import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Col, Dropdown, Badge, Accordion, Form, Modal, ToggleButton, Tooltip, OverlayTrigger, Alert, Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faFilePdf, faEllipsisV, faCommentAlt, faArrowLeft, faPlusCircle, faGavel, faTrash, faTasks, faDownload, faShare, faPen, faEye, faAddressBook, faPhoneAlt, faEnvelope, faMapMarkerAlt, faUser, faIdCard, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { get, put, post, del } from '../../../controllers/endpoints.controller';
import { faEdit, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { pn } from '../../../tools/chains';
import { convert2Base64, processDate, timeDue, getFileIcon, timeDueWithBadge } from '../../../tools/tools';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { params, colors } from '../../../tools/constants'
import { getUserToken, logout, getUserRole } from '../../../controllers/user.controller';
import PDFViewer from 'mgr-pdf-viewer-react';
import { getDateMeta } from '@fullcalendar/react';

export default function CourtFileDetails() {

    const PAGE_TITLE = "Expediente";
    const PAGE_ICON = faGavel;
    const [courtFile, setCourtFile] = React.useState({});
    const [debt, setDebt] = React.useState({});
    const [startDate, setStartDate] = React.useState(new Date());
    const [canChooseGDD, setCanChooseGDD] = React.useState(false);
    const [customer, setCustomer] = React.useState(null);
    const [customerContacts, setCustomerContacts] = React.useState([]);
    const [error, setError] = React.useState(<div></div>);
    const [isArchived, setIsArchived] = React.useState(false);
    const [defendants, setDefendants] = React.useState([]);
    const [lawers, setLawers] = React.useState([]);
    const [lawer, setLawer] = React.useState(null);
    const [tasks, setTasks] = React.useState([]);
    const [showNewTaskModal, setShowNewTaskModal] = React.useState(false);
    const [reminderOptions, setReminderOptions] = React.useState([]);
    const [newTaskDate, setNewTaskDate] = React.useState(null);
    const [newTaskReminder, setNewTaskReminder] = React.useState(null);
    const [newTaskNotificationChecked, setNewTaskNotificationChecked] = React.useState(true);
    const [newTaskUser, setNewTaskUser] = React.useState({});
    const handleCloseNewTaskModal = () => { setShowNewTaskModal(false); };
    const handleShowNewTaskModal = () => {
        setError()
        setNewTaskNotificationChecked(false);
        setShowNewTaskModal(true);
    };
    const [showViewFileModal, setShowViewFileModal] = React.useState(false);
    const [actualViewFile, setActualViewFile] = React.useState("");
    const handleCloseViewFileModal = () => { setShowViewFileModal(false); };
    const handleShowViewFileModal = () => {
        setShowViewFileModal(true);
    };
    const [proceedingActionsColumns, setProceedingActionsColumns] = React.useState([]);
    const [proceedingActions, setProceedingActions] = React.useState([]);

    const [editActionId, setEditActionId] = React.useState(null);
    const [editActionDate, setEditActionDate] = React.useState(new Date());
    const [editActionUser, setEditActionUser] = React.useState("0");
    const [editActionCategory, setEditActionCategory] = React.useState("0");
    const [editActionDescription, setEditActionDescription] = React.useState("");
    const [editActionFoja, setEditActionFoja] = React.useState("");
    const [editActionFile, setEditActionFile] = React.useState(null);
    const [editActionFileName, setEditActionFileName] = React.useState("");
    const [editActionFileChanged, setEditActionFileChanged] = React.useState(false);
    const [showEditActionModal, setShowEditActionModal] = React.useState(false);
    const handleCloseEditActionModal = () => { setShowEditActionModal(false); };
    const handleShowEditActionModal = (data) => {
        console.log(data)
        setEditActionId(data.id)
        setEditActionDate(new Date(data.date));
        setEditActionUser(data.userId);
        setEditActionCategory(data.categoryId);
        setEditActionDescription(data.description);
        setEditActionFoja(data.foja);
        setEditActionFileChanged(false);
        if (data.fileId == null) {
            setEditActionFile(null)
            setEditActionFileName(null)
        } else {
            setEditActionFile(null)
            setEditActionFileName(
                <Row>
                    <Col>
                        <Alert className='m-0' variant='secondary'>{data.file.name}</Alert>
                    </Col>
                    <Col xs={2} className='align-center-vertically'>
                        <Button variant='danger' onClick={deleteEditAction}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></Button>
                    </Col>
                </Row>
            )
        }

        setError()
        //setNewActionFile();

        setShowEditActionModal(true);
    };

    const deleteEditAction = () => {
        setEditActionFileChanged(true)
        setEditActionFileName(null)
    }

    const handleEditActionFile = async (event) => {
        const file = event.target.files[0]
        setEditActionFile(file)
        setEditActionFileChanged(true)
        setEditActionFileName(
            <Row>
                <Col>
                    <Alert className='m-0' variant='secondary'>{file.name}</Alert>
                </Col>
                <Col xs={2} className='align-center-vertically'>
                    <Button variant='danger' onClick={deleteEditAction}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></Button>
                </Col>
            </Row>
        )
    }



    const [showNewActionModal, setShowNewActionModal] = React.useState(false);
    const [actionCategories, setActionCategories] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState({});
    const [selectedLawer, setSelectedLawer] = React.useState("0");
    const [selectedActionCategory, setSelectedActionCategory] = React.useState("0");
    const handleCloseNewActionModal = () => { setShowNewActionModal(false); };
    const handleShowNewActionModal = () => {
        setNewActionDate(new Date())
        setError()
        setSelectedLawer("0")
        setSelectedActionCategory("0")
        setNewActionFile();
        getActionCategories();
        getLawers();
        setShowNewActionModal(true);
    };
    const [showChangeModuleModal, setShowChangeModuleModal] = React.useState(false);
    const [selectedModule, setSelectedModule] = React.useState("");
    const [newActionFile, setNewActionFile] = React.useState(null);
    const [newActionDate, setNewActionDate] = React.useState(new Date());
    const handleCloseChangeModuleModal = () => { setShowChangeModuleModal(false); };
    const handleShowChangeModuleModal = () => {
        setShowChangeModuleModal(true);
    };
    const [showEditDataModal, setShowEditDataModal] = React.useState(false);
    const [editDataName, setEditDataName] = React.useState("");
    const [editDataNumber, setEditDataNumber] = React.useState("");
    const [editDataCourt, setEditDataCourt] = React.useState("");
    const handleCloseEditDataModal = () => { setShowEditDataModal(false); };
    const handleShowEditDataModal = () => {
        setShowEditDataModal(true);
        //document.getElementById('editDataName').value = courtFile.name;
        setEditDataName(courtFile.name)
        setEditDataNumber(courtFile.number)
        setEditDataCourt(courtFile.court)
    };

    const [showDeleteProceedingModal, setShowDeleteProceedingModal] = React.useState(false);
    const handleCloseDeleteProceedingModal = () => { setShowDeleteProceedingModal(false); };
    const handleShowDeleteProceedingModal = () => {
        setShowDeleteProceedingModal(true);
    }

    const [showDeleteActionModal, setShowDeleteActionModal] = React.useState(false);
    const [selectedActionId, setSelectedActionId] = React.useState("0");
    const [selectedActionDescription, setSelectedActionDescription] = React.useState("");
    const handleCloseDeleteActionModal = () => { setShowDeleteActionModal(false); };
    const handleShowDeleteActionModal = (id, description) => {
        setSelectedActionId(id)
        setSelectedActionDescription(description)
        setShowDeleteActionModal(true);
    }

    const [showEditLawerModal, setShowEditLawerModal] = React.useState(false);
    const [editLawerName, setEditLawerName] = React.useState("");
    const [editLawerSurname, setEditLawerSurname] = React.useState("");
    const [editLawerDni, setEditLawerDni] = React.useState("");
    const [editLawerFirm, setEditLawerFirm] = React.useState("");
    const [editLawerPhone, setEditLawerPhone] = React.useState("");
    const [editLawerEmail, setEditLawerEmail] = React.useState("");
    const [editLawerAddress, setEditLawerAddress] = React.useState("");
    const [editLawerCity, setEditLawerCity] = React.useState("");
    const [editLawerProvince, setEditLawerProvince] = React.useState("");
    const [editLawerPostalCode, setEditLawerPostalCode] = React.useState("");
    const handleCloseEditLawerModal = () => { setShowEditLawerModal(false); };
    const handleShowEditLawerModal = () => {
        setShowEditLawerModal(true);
        setEditLawerName(lawer.name)
        setEditLawerSurname(lawer.surname)
        setEditLawerDni(lawer.dni)
        setEditLawerFirm(lawer.firm)
        setEditLawerPhone(lawer.phone)
        setEditLawerEmail(lawer.email)
        setEditLawerAddress(lawer.address)
        setEditLawerCity(lawer.city)
        setEditLawerProvince(lawer.province)
        setEditLawerPostalCode(lawer.postalCode)
    };

    const [showEditTaskModal, setShowEditTaskModal] = React.useState(false);
    const handleCloseEditTaskModal = () => { setShowEditTaskModal(false); };
    const handleShowEditTaskModal = (t) => {
        setEditTaskUser(t.userId)
        setEditTaskTitle(t.title)
        setEditTaskDescription(t.description)
        setEditTaskDate(t.dueDate == null ? null : new Date(t.dueDate))
        setEditTaskReminder(null)
        setShowEditTaskModal(true);
        setEditTaskId(t.id)
    };

    const [editTaskId, setEditTaskId] = React.useState(null);
    const [editTaskUser, setEditTaskUser] = React.useState(null);
    const [editTaskTitle, setEditTaskTitle] = React.useState(null);
    const [editTaskDescription, setEditTaskDescription] = React.useState(null);
    const [editTaskDate, setEditTaskDate] = React.useState(null);
    const [editTaskReminder, setEditTaskReminder] = React.useState(null);

    const handleEditTaskUser = (event) => {
        setEditTaskUser(event.target.value)
    };

    const handleEditTaskReminder = (event) => {
        setEditTaskReminder(event.target.value)
    };

    const handleEditTaskDate = (event) => {
        setEditTaskDate(event);
    };

    const handleEditDataName = (event) => {
        setEditDataName(event.target.value)
    };

    const handleEditDataNumber = (event) => {
        setEditDataNumber(event.target.value)
    };

    const handleEditDataCourt = (event) => {
        setEditDataCourt(event.target.value)
    };



    const handleEditLawerName = (event) => {
        setEditLawerName(event.target.value)
    };

    const handleEditLawerSurname = (event) => {
        setEditLawerSurname(event.target.value)
    };

    const handleEditLawerDni = (event) => {
        setEditLawerDni(event.target.value)
    };

    const handleEditLawerFirm = (event) => {
        setEditLawerFirm(event.target.value)
    };

    const handleEditLawerPhone = (event) => {
        setEditLawerPhone(event.target.value)
    };

    const handleEditLawerEmail = (event) => {
        setEditLawerEmail(event.target.value)
    };

    const handleEditLawerAddress = (event) => {
        setEditLawerAddress(event.target.value)
    };

    const handleEditLawerCity = (event) => {
        setEditLawerCity(event.target.value)
    };

    const handleEditLawerProvince = (event) => {
        setEditLawerProvince(event.target.value)
    };

    const handleEditLawerPostalCode = (event) => {
        setEditLawerPostalCode(event.target.value)
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        registerLocale("es", { ...es, options: { ...es.options, weekStartsOn: 0 } });
        document.title = `EAC - ${PAGE_TITLE}`;

        getData();
    }, []);

    const getData = async () => {
        getProceedingById(getProceedingId());
        getTasks();
        getReminderOptions();
        getLawers();
        getActionCategories();
    }

    const handleNewTaskUser = (event) => {
        setNewTaskUser(event.target.value)
    };

    const handleNewTaskNotification = (event) => {
        setNewTaskNotificationChecked(event)
    };

    const handleNewActionUser = (event) => {
        setSelectedLawer(event.target.value)
    };

    const handleNewActionCategory = (event) => {
        setSelectedActionCategory(event.target.value)
    };

    const handleNewTaskReminder = (event) => {
        setNewTaskReminder(event.target.value)
    };

    const handleNewTaskDate = (event) => {
        setNewTaskDate(event);
    };

    const handleNewActionDate = (event) => {
        setNewActionDate(event);
    };

    const handleEditActionDate = (event) => {
        setEditActionDate(event);
    };

    const handleSelectedModule = (event) => {
        setSelectedModule(event.target.value);
    };

    const getProceedingId = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const getLawers = async function () {
        const req = await get(`/users/lawers`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setLawers(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const getActionCategories = async function () {
        const req = await get(`/proceedings/actions/categories`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setActionCategories(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getReminderOptions = async function () {
        const req = await get(`/tasks/reminderOptions/`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setReminderOptions(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getTasks = async function () {
        const req = await get(`/tasks/byProceedingId/${getProceedingId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setTasks(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createTask = async function () {
        if (checkNewTask()) {
            const task = {
                proceedingId: getProceedingId(),
                userId: newTaskUser,
                dueDate: newTaskDate,
                alertId: newTaskReminder,
                title: document.getElementById("newTaskTitle").value,
                description: document.getElementById("newTaskDescription").value,
                isEvent: true,
                sendNotification: newTaskNotificationChecked
            };

            const req = await post(`/tasks/`, getUserToken(), task);
            const res = await req.json();

            if (req.status === 201) {
                getTasks();
                handleCloseNewTaskModal();
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const setTaskAsCompleted = async function (taskId) {
        const req = await put(`/tasks/byProceedingId/asCompleted/`, getUserToken(), { taskId: taskId, proceedingId: getProceedingId() });
        const res = await req.json();

        if (req.status === 200) {
            getTasks();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processContacts = function (data) {
        let result = [];
        let aux;
        let icon;

        for (let c of data) {
            aux = null;

            for (let i = 0; i < c.ContactData.length; i++) {
                switch (c.ContactData[i].param) {
                    case "PHON":
                        icon = <FontAwesomeIcon icon={faPhoneAlt}></FontAwesomeIcon>;
                        break;
                    case "EMAI":
                        icon = <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>;
                        break;
                    case "ADDR":
                        icon = <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>;
                        break;
                    case "POSI":
                        icon = <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>;
                        break;
                    case "DNIN":
                        icon = <FontAwesomeIcon icon={faIdCard}></FontAwesomeIcon>;
                        break;
                    case "OTHE":
                        icon = <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>;
                        break;
                    default:
                        break;
                }

                if (i === 0) {
                    aux = <span>{icon} {c.ContactData[i].data}</span>;
                } else {
                    aux = <span>{aux} | {icon} {c.ContactData[i].data}</span>;
                }
            }
            c.data = aux;
            //c.action = <span><Button variant="success" onClick={() => handleShowNewContactData(c.id)}><FontAwesomeIcon icon={faAddressCard} style={{marginRight: 8}}></FontAwesomeIcon>Añadir</Button><Button className='ms-1' variant="danger" onClick={() => handleShowSureDelete(c.name, c.id)}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></Button></span>
            result.push(c);
        }
        setCustomerContacts(result);
    }

    const getProceedingById = async function (id) {
        const req = await get(`/proceedings/courtFiles/byId/${id}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setDebt(res.debt);
            //setStatus(res.status);
            console.log(res.debtId)
            if (res.debtId != null) {
                setCanChooseGDD(true)
            }

            if (res.ProceedingCustomer.Business) {
                if (res.ProceedingCustomer.Business.code) {
                    res.ProceedingCustomer.Business.code = <Badge bg='' className='ms-1 ' style={{ backgroundColor: `${res.ProceedingCustomer.Business.color}` }}>{res.ProceedingCustomer.Business.code}</Badge>;
                }
            }
            setCustomer(res.ProceedingCustomer)
            if (res.ProceedingCustomer.Business) {
                getCustomerContacts(res.ProceedingCustomer.businessId);
            }

            setIsArchived(res.isArchived);
            setCourtFile(res.CourtFile);
            setLawer(res.CourtFile.CourtFileLawer);
            //setDefendants(res.ProceedingDefendants);

            let defs = [];
            for (let def of res.ProceedingDefendants) {
                if (def.businessId == null && def.personId == null) {

                } else {
                    defs.push(def)
                }
            }
            setDefendants(defs)

            setProceedingActionsColumns(['Fecha', 'Categoría', 'Tarea', 'Foja', '', 'Encargado', '']);
            let pa = [];
            for (let dad of res.Actions) {
                dad.action = (
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {dad.file != null ? <div>{getFileIcon(dad.file.url) == faFilePdf ? <Dropdown.Item onClick={() => viewFile(dad.file.id, dad.file.name)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 8, marginLeft: 2 }} />Ver archivo</Dropdown.Item> : ""}</div> : <div></div>}
                            {dad.file != null ? <Dropdown.Item onClick={() => downloadFile(dad.file.id, dad.file.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 8, marginLeft: 2 }} />Descargar archivo</Dropdown.Item> : <div></div>}
                            <Dropdown.Item onClick={() => handleShowEditActionModal(dad)}><FontAwesomeIcon icon={faPen} style={{ marginRight: 10, marginLeft: 2 }} />Modificar</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleShowDeleteActionModal(dad.id, dad.description)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
                dad.fileIcon = dad.file != null ? (
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">{dad.file.name}</Tooltip>}>

                        <span><FontAwesomeIcon style={{ fontSize: 30 }} icon={getFileIcon(dad.file.url)}></FontAwesomeIcon></span>
                    </OverlayTrigger>


                ) : <div></div>
                dad.category = <Badge bg='' className='content-table status' style={{ backgroundColor: `${dad.actionCategory.color}` }}>{dad.actionCategory.name}</Badge>;
                dad.createdBy = `${dad.User.name} ${(dad.User.surname).substring(0, 1)}.`;
                pa.push(dad);
            }
            setProceedingActions(pa);

        } else if (req.status === 403) {
            logout();
        } else if (req.status === 220) {
            window.location.href = "/expedientes";
        } else {
            alert(res.message);
        }
    }

    const getCustomerContacts = async function (id) {
        const req = await get(`/customers/business/contacts/${id}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processContacts(res)
        } else {
            alert(res.message);
        }
    }

    const createAction = async function () {
        if (checkNewAction()) {
            let file = null;
            let fileName = null;
            let fileExtension = null;

            if (newActionFile != null) {
                file = await convert2Base64(newActionFile);
                const aux = newActionFile.name.split('.')
                fileExtension = `.${aux[aux.length - 1]}`
                fileName = newActionFile.name;
            }

            const ac = {
                proceedingId: getProceedingId(),
                userId: selectedLawer,
                categoryId: selectedActionCategory,
                file: file,
                fileName: fileName,
                fileExtension: fileExtension,
                description: document.getElementById('newActionDescription').value,
                foja: document.getElementById('newActionFoja').value,
                date: newActionDate
            };

            const req = await post(`/proceedings/actions/`, getUserToken(), ac);
            const res = await req.json();

            if (req.status === 201) {
                getProceedingById(getProceedingId());
                handleCloseNewActionModal();
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const editAction = async function () {
        if (checkEditAction()) {
            let file = null;
            let fileName = null;
            let fileExtension = null;

            let body = {
                proceedingId: getProceedingId(),
                id: editActionId,
                userId: parseInt(document.getElementById('editActionUser').value),
                categoryId: document.getElementById('editActionCategory').value == "0" ? null : parseInt(document.getElementById('editActionCategory').value),
                description: document.getElementById('editActionDescription').value,
                foja: document.getElementById('editActionFoja').value,
                date: editActionDate
            };

            if (editActionFileChanged) {
                if (editActionFile != null) {
                    file = await convert2Base64(editActionFile);
                    const aux = editActionFile.name.split('.')
                    fileExtension = `.${aux[aux.length - 1]}`
                    fileName = editActionFile.name;

                    body.file = file;
                    body.fileName = fileName;
                    body.fileExtension = fileExtension;
                } else {
                    body.file = null;
                }
            }

            const req = await put(`/proceedings/actions/`, getUserToken(), body);
            const res = await req.json();

            if (req.status === 201) {
                getProceedingById(getProceedingId());
                handleCloseEditActionModal();
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const changeModule = async function () {
        const req = await put(`/proceedings/module`, getUserToken(), { module: selectedModule, proceedingId: getProceedingId() });
        const res = await req.json();

        if (req.status === 200) {
            if (selectedModule == "Archivo") {
                window.location.href = '/archivo'
            } else if (selectedModule == "Judicial") {
                window.location.href = '/expedientes'
            } else if (selectedModule == "Deuda") {
                window.location.href = '/moras'
            }

        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const editData = async function () {
        const data = {
            proceedingId: getProceedingId(),
            courtFileId: courtFile.id,
            name: editDataName,
            number: editDataNumber,
            court: editDataCourt
        }
        const req = await put(`/proceedings/courtFiles/data`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getProceedingById(getProceedingId())
            handleCloseEditDataModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const editDefendantLawer = async function () {
        const data = {
            proceedingId: getProceedingId(),
            lawerId: lawer.id,
            name: editLawerName,
            surname: editLawerSurname,
            dni: editLawerDni,
            phone: editLawerPhone,
            email: editLawerEmail,
            address: editLawerAddress,
            city: editLawerCity,
            province: editLawerProvince,
            postalCode: editLawerPostalCode,
            firm: editLawerFirm
        }
        const req = await put(`/proceedings/courtFiles/deflawer`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getProceedingById(getProceedingId())
            handleCloseEditLawerModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const handleNewActionFile = async (event) => {
        const file = event.target.files[0]
        setNewActionFile(file)
    }

    const downloadFile = async (fileId, name) => {
        const req = await get(`/files/byId/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            const linkSource = res.file;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = name;
            downloadLink.click();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const viewFile = async (fileId, name) => {
        const req = await get(`/files/byIdNoHeader/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setActualViewFile(res.file)
            handleShowViewFileModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteAction = async function () {
        const data = {
            proceedingId: getProceedingId(),
            id: selectedActionId
        }
        const req = await del(`/proceedings/actions/`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getProceedingById(getProceedingId())
            handleCloseDeleteActionModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteProceeding = async function () {
        const data = {
            proceedingId: getProceedingId(),
        }
        const req = await del(`/proceedings/`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = "/expedientes"
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const checkNewTask = () => {
        let result = true;

        if (newTaskUser == '0') {
            result = false;
            showError('No se ha asignado un encargado')
        } else if (document.getElementById("newTaskTitle").value.length <= 1) {
            result = false;
            showError('No se ha escrito el título')
        }

        return result;
    }

    const checkNewAction = () => {
        let result = true;

        if (selectedLawer == '0') {
            result = false;
            showError('No se ha asignado un encargado')
        } else if (selectedActionCategory == '0') {
            result = false;
            showError('No se ha seleccionado una categoría')
        } else if (document.getElementById("newActionDescription").value.length <= 1) {
            result = false;
            showError('No se ha escrito una descripción')
        }

        return result;
    }

    const checkEditAction = () => {
        let result = true;

        if (document.getElementById('editActionCategory').value == '0') {
            result = false;
            showError('No se ha seleccionado una categoría')
        } else if (document.getElementById("editActionDescription").value.length <= 1) {
            result = false;
            showError('No se ha escrito una descripción')
        }

        return result;
    }

    const showError = (title) => {
        setError(
            <Alert className='mt-3 mb-0' key='danger' variant='danger'>
                {title}
            </Alert>
        )
    }

    const processLawer = () => {
        if (lawer) {
            if (lawer.name == null && lawer.phone == null && lawer.email == null) {
                return (

                    <Container className='mt-4 mb-4'>
                        <Row>
                            <Col style={{ textAlign: 'center', fontSize: 40 }}>
                                <FontAwesomeIcon icon={faGavel} />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: 'center' }}>
                                No hay abogado contrario asignado
                            </Col>
                        </Row>
                    </Container>
                )

            } else {
                return (
                    <table>
                        <tr>
                            <td style={{ fontWeight: 'bold' }}>Nombre</td>
                            <td className='ps-5'>{lawer.surname != null ? lawer.surname.toUpperCase() : ""}, {lawer.name}</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: 'bold' }}>Teléfono</td>
                            <td className='ps-5'>{lawer.phone}</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: 'bold' }}>Correo</td>
                            <td className='ps-5'>{lawer.email}</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: 'bold' }}>Firma</td>
                            <td className='ps-5'>{lawer.firm}</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: 'bold' }}>Domicilio</td>

                            <td className='ps-5'>{lawer.address}<br></br>{lawer.city && lawer.province ? `${lawer.city} - ${lawer.province}` : ``}</td>
                        </tr>
                    </table>
                )


            }
        }

    }

    const updateTask = async function () {
        if (checkEditTask()) {
            const task = {
                userId: editTaskUser,
                dueDate: editTaskDate,
                alertId: editTaskReminder,
                title: document.getElementById("editTaskTitle").value,
                description: document.getElementById("editTaskDescription").value,
                isEvent: true,
                taskId: editTaskId
            };

            const req = await put(`/tasks/`, getUserToken(), task);
            const res = await req.json();

            if (req.status === 201) {
                getTasks();
                handleCloseEditTaskModal();
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }

            console.log(task)
        }
    }

    const checkEditTask = () => {
        let result = true;

        if (editTaskUser == '0') {
            result = false;
            showError('No se ha asignado un encargado')
        } else if (document.getElementById("editTaskTitle").value.length <= 1) {
            result = false;
            showError('No se ha escrito el título')
        }

        return result;
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td className='pe-5'>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 25, fontWeight: 300 }}>{PAGE_TITLE} #{courtFile.number}</p>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{courtFile.name ? (courtFile.name.length > 75 ? `${courtFile.name.substring(0, 75)}...` : courtFile.name) : ""}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    {getUserRole() != "Cliente" ?
                                        <Link to={'/expedientes'} className='link-primary'>
                                            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Judiciales
                                        </Link>
                                        :
                                        <Link to={'/expedientes'} className='link-primary'>
                                            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Gestión de expedientes
                                        </Link>
                                    }

                                    {getUserRole() != "Cliente" ?
                                        <span><span className='ms-2'>|</span><span className='ms-2'>{courtFile.User ? <img className='avatar-30 me-2' style={{ objectFit: 'cover' }} src={`${params().dataUri}${courtFile.User.avatar}`}></img> : ""}{courtFile.User ? `${courtFile.User.name} ${courtFile.User.surname}` : ""}</span></span>
                                        :
                                        <div></div>
                                    }


                                </td>
                                <td className='content-options rows right'>
                                    {getUserRole() != "Cliente" ?
                                        <Dropdown>
                                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {!isArchived ? <Dropdown.Item onClick={handleShowEditLawerModal}><FontAwesomeIcon icon={faPen} style={{ marginRight: 8, marginLeft: 2 }} />Modificar abogado contrario</Dropdown.Item> : ""}
                                                {!isArchived ? <Dropdown.Item onClick={handleShowEditDataModal}><FontAwesomeIcon icon={faPen} style={{ marginRight: 8, marginLeft: 2 }} />Modificar datos del expediente</Dropdown.Item> : ""}
                                                <Dropdown.Item onClick={handleShowChangeModuleModal}><FontAwesomeIcon icon={faShare} style={{ marginRight: 8, marginLeft: 2 }} />Mover de módulo</Dropdown.Item>
                                                <Dropdown.Item onClick={handleShowDeleteProceedingModal}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        :
                                        <div></div>
                                    }

                                </td>
                            </tr>
                        </table>
                        <Container>
                            <Row>
                                {customer ?
                                    <div>
                                        {customer.Business || customer.Person ?

                                            <Accordion className='p-0 mt-3' >
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <p className='m-0'>
                                                            Datos del cliente: <span>{customer.Person != null ? `${customer.Person.surname.toUpperCase()}, ${customer.Person.name}` : <span>{customer.Business.legalName} {customer.Business.code}</span>}</span>
                                                        </p>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <Row>
                                                            <Col xs={6}>
                                                                <p className='column-title'>Datos del cliente</p>
                                                            </Col>
                                                            <Col xs={6}>
                                                                {customer.Business ?
                                                                    <p className='column-title'>Contactos</p>
                                                                    :
                                                                    <div></div>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row className='mb-3' style={{ marginTop: -16 }}>

                                                            <Col xs={6}>
                                                                {customer.businessId == null ?
                                                                    //Its a person
                                                                    <div className='column-content'>
                                                                        <table>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Nombre</td>
                                                                                <td className='ps-5'>{customer.Person != null ? customer.Person.surname.toUpperCase() : ""}, {customer.Person.name}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>DNI</td>
                                                                                <td className='ps-5'>{customer.Person.dni}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Teléfono</td>
                                                                                <td className='ps-5'>{customer.Person.phone}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Celular</td>
                                                                                <td className='ps-5'>{customer.Person.mobile}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Correo</td>
                                                                                <td className='ps-5'>{customer.Person.email}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Trabajo</td>
                                                                                <td className='ps-5'>{customer.Person.isWorking ? `Si - ${customer.Person.placeWork}` : 'No'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Domicilio</td>
                                                                                <td className='ps-5'>{customer.Person.address}<br></br>{customer.Person.city != null ? `${customer.Person.city}` : ''}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                    :
                                                                    //Its a business
                                                                    <div className='column-content'>
                                                                        <table>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Nombre</td>
                                                                                <td className='ps-5'>{customer.Business.legalName}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>CUIT</td>
                                                                                <td className='ps-5'>{customer.Business.cuit}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Orden</td>
                                                                                <td className='ps-5'>{customer.Business.educationalNetwork != null ? customer.Business.educationalNetwork.name : "---"}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Domicilio</td>
                                                                                <td className='ps-5'>{customer.Business.address}<br></br>{customer.Business.city != null ? `${customer.Business.city}` : ''}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                }
                                                            </Col>
                                                            <Col xs={6}>
                                                                {customer.Business ?
                                                                    <div className='column-content'>
                                                                        {customerContacts.length > 0 ?
                                                                            <Table className=''>

                                                                                <tbody>
                                                                                    {customerContacts.map((r, i) => (
                                                                                        <tr key={i} >
                                                                                            <td className='content-table rows align-middle ps-4'>{r.name}</td>
                                                                                            <td className='content-table rows align-middle'>{r.data}</td>
                                                                                        </tr>
                                                                                    ))}

                                                                                </tbody>
                                                                            </Table>

                                                                            :
                                                                            <Container className='mt-4 mb-4'>
                                                                                <Row>
                                                                                    <Col style={{ textAlign: 'center', fontSize: 40 }}>
                                                                                        <FontAwesomeIcon icon={faAddressBook} />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col style={{ textAlign: 'center' }}>
                                                                                        No hay contactos
                                                                                    </Col>
                                                                                </Row>
                                                                            </Container>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <div></div>
                                                                }
                                                            </Col>

                                                        </Row>

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>

                                            :
                                            <div></div>
                                        }
                                    </div>

                                    :
                                    <div></div>

                                }

                            </Row>



                            <Row>
                                {defendants.length > 0 ?
                                    <Container>
                                        <Accordion className='p-0 mt-3' >
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <p className='m-0'>
                                                        Datos del contrario/s: {defendants.map((d, i) => (<span>{d.Person != null ? `${d.Person.surname.toUpperCase()}, ${d.Person.name}${defendants.length > (i + 1) ? "; " : ""}` : `${d.Business.legalName}${defendants.length > (i + 1) ? "; " : ""}`}</span>))}
                                                    </p>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Row>
                                                        {defendants.map((d, i) => (
                                                            <Col xs={4}>
                                                                <p className='column-title'>Contrario {i + 1}</p>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                    <Row className='mb-3' style={{ marginTop: -16 }}>
                                                        {defendants.map((d, i) => (
                                                            <Col xs={4}>
                                                                {d.businessId == null ?
                                                                    //Its a person
                                                                    <div className='column-content'>
                                                                        <table>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Nombre</td>
                                                                                <td className='ps-5'>{d.Person != null ? d.Person.surname.toUpperCase() : ""}, {d.Person.name}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>DNI</td>
                                                                                <td className='ps-5'>{d.Person.dni}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Teléfono</td>
                                                                                <td className='ps-5'>{d.Person.phone}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Celular</td>
                                                                                <td className='ps-5'>{d.Person.mobile}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Correo</td>
                                                                                <td className='ps-5'>{d.Person.email}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Trabajo</td>
                                                                                <td className='ps-5'>{d.Person.isWorking ? `Si - ${d.Person.placeWork}` : 'No'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Domicilio</td>
                                                                                <td className='ps-5'>{d.Person.address}<br></br>{d.Person.city != null ? `${d.Person.city}` : ''}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                    :
                                                                    //Its a business
                                                                    <div className='column-content'>
                                                                        <table>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Nombre</td>
                                                                                <td className='ps-5'>{d.Business.legalName}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>CUIT</td>
                                                                                <td className='ps-5'>{d.Business.cuit}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Orden</td>
                                                                                <td className='ps-5'>{d.Business.educationalNetwork != null ? d.Business.educationalNetwork.name : "---"}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Domicilio</td>
                                                                                <td className='ps-5'>{d.Business.address}<br></br>{d.Business.city != null ? `${d.Business.city}` : ''}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                }
                                                            </Col>
                                                        ))}
                                                    </Row>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Container>
                                    :
                                    <div></div>

                                }

                            </Row>


                            <Row className='mt-4 mb-0'>

                                <Col>
                                    <p className='column-title'>Datos del abogado</p>
                                </Col>
                                <Col>
                                    <p className='column-title'>Datos del expediente</p>
                                </Col>
                                <Col>
                                    {courtFile.debtId != null ? <p className='column-title'>Datos de la deuda</p> : <div></div>}
                                </Col>
                            </Row>
                            <Row className='mb-4' style={{ marginTop: -16 }}>

                                <Col>
                                    <div className='column-content'>
                                        {processLawer()}


                                    </div>
                                </Col>
                                <Col>
                                    <div className='column-content'>
                                        <table>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>Carátula</td>
                                                <td className='ps-5'>{courtFile.name}</td>
                                            </tr>
                                            <tr>
                                                <td className="pt-3" style={{ fontWeight: 'bold' }}>Expediente</td>
                                                <td className='pt-3 ps-5'>{courtFile.number}</td>
                                            </tr>
                                            <tr>
                                                <td className="pt-3" style={{ fontWeight: 'bold' }}>Fuero</td>
                                                <td className='pt-3 ps-5'>{courtFile.court}</td>
                                            </tr>

                                        </table>
                                    </div>
                                </Col>
                                <Col>
                                    {courtFile.debtId != null ?
                                        <div className='column-content'>
                                            <table>
                                                <tr>
                                                    <td style={{ fontWeight: 'bold' }}>Capital</td>
                                                    <td className='ps-5'>${pn(debt.interests)}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: 'bold' }}>Intereses</td>
                                                    <td className='ps-5'>${pn(debt.interests)}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: 'bold' }}>Honorarios</td>
                                                    <td className='ps-5'>${pn(debt.totalFee)} ({debt.fee}%)</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: 'bold' }}>Total deuda</td>
                                                    <td className='ps-5'>${pn(debt.totalDebt)}</td>
                                                </tr>

                                            </table>
                                        </div>
                                        :
                                        <div></div>
                                    }

                                </Col>
                            </Row>
                        </Container>
                    </Card>
                </Row>

                {getUserRole() != "Cliente" ?
                    <div>
                        {isArchived ?
                            <div></div>
                            :
                            <Row>
                                <Card className='cards content mb-5'>
                                    <table className='content-options content'>
                                        <tr>
                                            <td className='content-options rows left'>
                                                Tareas
                                            </td>
                                            <td className='content-options rows right'>
                                                <Button variant="success" onClick={handleShowNewTaskModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva tarea</Button>
                                            </td>
                                        </tr>
                                    </table>

                                    <Container className='mt-3 mb-3'>
                                        {tasks.length > 0 ?
                                            tasks.map((t, i) => (
                                                <div className='file element mb-2' >
                                                    <table>
                                                        <tr>
                                                            <td className='w-100'>
                                                                <p className='m-0'>{t.title}</p>
                                                                <p className='m-0' style={{ fontSize: 12 }}>{t.description}</p>
                                                                <p className='mt-1 mb-0'>{timeDueWithBadge(t.dueDate)}</p>
                                                                <p className='mt-2 mb-0' style={{ fontSize: 12 }}><img className='me-1 avatar-20' style={{ objectFit: 'cover' }} src={`${params().dataUri}${t.User.avatar}`} alt=""></img> <span style={{ marginTop: 'auto', marginBottom: 'auto', height: 20, verticalAlign: 'middle' }}>{t.User.name} {t.User.surname}</span></p>
                                                            </td>
                                                            <td className='w-100'>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item onClick={() => handleShowEditTaskModal(t)}><FontAwesomeIcon icon={faPen} style={{ marginRight: 7, marginLeft: 2 }} />Modificar</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => setTaskAsCompleted(t.id)}><FontAwesomeIcon icon={faCheck} style={{ marginRight: 10, marginLeft: 2 }} />Marcar como completado</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            ))
                                            :
                                            <Container className='mt-4 mb-4'>
                                                <Row>
                                                    <Col style={{ textAlign: 'center', fontSize: 40 }}>
                                                        <FontAwesomeIcon icon={faTasks} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col style={{ textAlign: 'center' }}>
                                                        No hay tareas pendientes
                                                    </Col>
                                                </Row>
                                            </Container>
                                        }
                                    </Container>

                                </Card>
                            </Row>
                        }
                    </div>
                    :
                    <div></div>
                }




                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    Gestión
                                </td>
                                <td className='content-options rows right'>
                                    {isArchived ?
                                        <div></div>
                                        :
                                        <div >
                                            {getUserRole() != "Cliente" ?
                                                <Button variant="success" onClick={handleShowNewActionModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva actividad</Button>
                                                :
                                                <div></div>
                                            }

                                        </div>
                                    }
                                </td>
                            </tr>
                        </table>

                        {proceedingActions.length > 0 ?
                            <Container className='pt-3 mb-3'>
                                <div className='d-none d-sm-block'>
                                    <Row className=' p-2 mb-2' style={{ background: `${colors().brand_blue}`, color: 'white', borderRadius: 8 }}>
                                        <Col sm={1} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Fecha
                                            </div>
                                        </Col>
                                        <Col sm={4} className='align-center-vertically-v2'>
                                            <div>
                                                Tarea
                                            </div>
                                        </Col>


                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Categoría
                                            </div>
                                        </Col>
                                        <Col sm={1} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Foja
                                            </div>
                                        </Col>
                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Encargado
                                            </div>
                                        </Col>
                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>

                                            </div>
                                        </Col>
                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {proceedingActions.map((r, i) => (
                                    <div>
                                        <div className='d-none d-sm-block'>
                                            <Row className='p-2 mb-2' style={{ background: r.income ? '#d8eddd' : '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={1} className='align-center-vertically'>
                                                    <Moment format="DD/MM/YY">{r.date}</Moment>
                                                </Col>
                                                <Col xs={4} className='align-center-vertically'>
                                                    <div>
                                                        <p className='m-0'>{r.description}</p>
                                                    </div>
                                                </Col>
                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <p className='m-0'>{r.category}</p>
                                                    </div>
                                                </Col>
                                                <Col xs={1} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <p className='m-0'>{r.foja}</p>
                                                    </div>
                                                </Col>

                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <p className='m-0'><img className='avatar-30 me-2' style={{ objectFit: 'cover' }} src={`${params().dataUri}${r.User.avatar}`} />{r.createdBy}</p>
                                                    </div>
                                                </Col>

                                                <Col xs={1} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <p className='m-0'>{r.fileIcon}</p>
                                                    </div>
                                                </Col>


                                                <Col xs={1} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <p className='m-0'>{r.action}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>


                                    </div>

                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCommentAlt}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron items en gestión</p>
                                </Row>
                            </Container>
                        }
                    </Card>
                </Row>


                <Modal show={showNewTaskModal} onHide={handleCloseNewTaskModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nueva tarea</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Form.Group>
                            <Form.Text>Encargado</Form.Text>
                            <Row>
                                <Col>
                                    <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskUser(event)}>
                                        <option value="0">Seleccionar...</option>
                                        {lawers.map((p, i) => (
                                            <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col xs={3} style={{ textAlign: 'right' }}>
                                    <ToggleButton
                                        className=""
                                        id="newTaskNotification"
                                        type="checkbox"
                                        variant="outline-success"
                                        checked={newTaskNotificationChecked}
                                        value="1"
                                        onChange={(e) => handleNewTaskNotification(e.currentTarget.checked)}
                                    >
                                        Notificar
                                    </ToggleButton>
                                </Col>
                            </Row>



                        </Form.Group>

                        <Form.Group className='mt-2'>
                            <Form.Text>Título</Form.Text>
                            <Form.Control type="text" id="newTaskTitle" placeholder="" />
                        </Form.Group>

                        <Form.Group className='mt-2'>
                            <Form.Text>Descripción</Form.Text>
                            <Form.Control as="textarea" rows={4} id="newTaskDescription" placeholder="" />
                        </Form.Group>

                        <Form.Group className='mt-2'>
                            <Form.Text>Fecha</Form.Text>
                            <DatePicker className='form-control cover content rows element' locale="es" minDate={new Date()} id='' dateFormat="dd/MM/yyyy" selected={newTaskDate} onChange={(d) => handleNewTaskDate(d)} />
                        </Form.Group>

                        <Form.Group className='mt-2'>
                            <Form.Text>Recordar</Form.Text>
                            <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskReminder(event)}>
                                <option value="0">No recordar</option>
                                {reminderOptions.map((p, i) => (
                                    <option key={i} id={i} value={p.id}>{p.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        {error}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseNewTaskModal}>
                            Cerrar
                        </Button>

                        <Button variant="danger" onClick={createTask}>Confirmar</Button>


                    </Modal.Footer>
                </Modal>

                <Modal show={showNewActionModal} onHide={handleCloseNewActionModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nueva actividad</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Text>Fecha</Form.Text>
                            <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newActionDate} onChange={(d) => handleNewActionDate(d)} />
                        </Form.Group>

                        <Form.Group className='mt-2'>
                            <Form.Text>Responsable</Form.Text>
                            <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewActionUser(event)}>
                                <option value="0">Seleccionar...</option>
                                {lawers.map((p, i) => (
                                    <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mt-2'>
                            <Form.Text>Categoría</Form.Text>
                            <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewActionCategory(event)}>
                                <option value="0">Seleccionar...</option>
                                {actionCategories.map((p, i) => (
                                    <option key={i} id={i} value={p.id}>{p.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mt-2'>
                            <Form.Text>Gestión</Form.Text>
                            <Form.Control as="textarea" rows={4} id="newActionDescription" placeholder="" />
                        </Form.Group>

                        <Form.Group className='mt-2'>
                            <Form.Text>Foja</Form.Text>
                            <Form.Control type="text" id="newActionFoja" placeholder="" />
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Text>Archivo</Form.Text>
                            <Form.Control accept='.pdf, .docx, .xlsx, .png, .jpg, .jpeg, .zip' onChange={(event) => handleNewActionFile(event)} type="file" />
                        </Form.Group>

                        {error}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseNewActionModal}>
                            Cerrar
                        </Button>

                        <Button variant="danger" onClick={createAction}>Confirmar</Button>


                    </Modal.Footer>
                </Modal>


                <Modal show={showChangeModuleModal} onHide={handleCloseChangeModuleModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Mover de módulo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className=''>
                            <Form.Text>Módulo</Form.Text>
                            <Form.Select className='cover content rows element' id="" onChange={(event) => handleSelectedModule(event)}>
                                <option value="0">Seleccionar...</option>
                                {!isArchived ? <option value='Archivo'>Archivo</option> : ""}
                                {!isArchived && canChooseGDD ? <option value='Deuda'>Gestión de deudas</option> : ""}
                                {isArchived ? <option value='Judicial'>Judicial</option> : ""}
                            </Form.Select>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseChangeModuleModal}>
                            Cerrar
                        </Button>

                        <Button variant="danger" onClick={changeModule}>Mover</Button>


                    </Modal.Footer>
                </Modal>

                <Modal show={showEditDataModal} onHide={handleCloseEditDataModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Modificar datos del expediente</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className=''>
                            <Form.Text>Carátula</Form.Text>
                            <Form.Control type="text" id="editDataName" placeholder="" value={editDataName} onChange={(event) => handleEditDataName(event)} />
                        </Form.Group>
                        <Form.Group className='mt-2'>
                            <Form.Text>Expediente</Form.Text>
                            <Form.Control type="text" id="editDataNumber" placeholder="" value={editDataNumber} onChange={(event) => handleEditDataNumber(event)} />
                        </Form.Group>
                        <Form.Group className='mt-2'>
                            <Form.Text>Fuero</Form.Text>
                            <Form.Control type="text" id="editDataCourt" placeholder="" value={editDataCourt} onChange={(event) => handleEditDataCourt(event)} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseEditDataModal}>
                            Cerrar
                        </Button>

                        <Button variant="success" onClick={editData}>Confirmar</Button>


                    </Modal.Footer>
                </Modal>

                <Modal show={showEditLawerModal} onHide={handleCloseEditLawerModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Abogado contrario</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className=''>
                            <Form.Text>Nombre</Form.Text>
                            <Form.Control type="text" id="lawerName" placeholder="" value={editLawerName} onChange={(event) => handleEditLawerName(event)} />
                        </Form.Group>
                        <Form.Group className='mt-2'>
                            <Form.Text>Apellido</Form.Text>
                            <Form.Control type="text" id="lawerSurname" placeholder="" value={editLawerSurname} onChange={(event) => handleEditLawerSurname(event)} />
                        </Form.Group>
                        <Form.Group className='mt-2'>
                            <Form.Text>DNI</Form.Text>
                            <Form.Control type="text" id="lawerDNI" placeholder="" value={editLawerDni} onChange={(event) => handleEditLawerDni(event)} />
                        </Form.Group>
                        <Form.Group className='mt-2'>
                            <Form.Text>Estudio</Form.Text>
                            <Form.Control type="text" id="lawerFirm" placeholder="" value={editLawerFirm} onChange={(event) => handleEditLawerFirm(event)} />
                        </Form.Group>
                        <Form.Group className='mt-2'>
                            <Form.Text>Teléfono / Celular</Form.Text>
                            <Form.Control type="text" id="lawerPhone" placeholder="" value={editLawerPhone} onChange={(event) => handleEditLawerPhone(event)} />
                        </Form.Group>
                        <Form.Group className='mt-2'>
                            <Form.Text>Correo electrónico</Form.Text>
                            <Form.Control type="text" id="lawerEmail" placeholder="" value={editLawerEmail} onChange={(event) => handleEditLawerEmail(event)} />
                        </Form.Group>
                        <Form.Group className='mt-2'>
                            <Form.Text>Dirección</Form.Text>
                            <Form.Control type="text" id="lawerAddress" placeholder="" value={editLawerAddress} onChange={(event) => handleEditLawerAddress(event)} />
                        </Form.Group>
                        <Form.Group className='mt-2'>
                            <Form.Text>Localidad</Form.Text>
                            <Form.Control type="text" id="lawerCity" placeholder="" value={editLawerCity} onChange={(event) => handleEditLawerCity(event)} />
                        </Form.Group>
                        <Form.Group className='mt-2'>
                            <Form.Text>Provincia</Form.Text>
                            <Form.Control type="text" id="lawerProvince" placeholder="" value={editLawerProvince} onChange={(event) => handleEditLawerProvince(event)} />
                        </Form.Group>
                        <Form.Group className='mt-2'>
                            <Form.Text>Código postal</Form.Text>
                            <Form.Control type="text" id="lawerPostalCode" placeholder="" value={editLawerPostalCode} onChange={(event) => handleEditLawerPostalCode(event)} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseEditLawerModal}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={editDefendantLawer}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showDeleteActionModal} onHide={handleCloseDeleteActionModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Desea eliminar la gestión "{selectedActionDescription}"?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeleteActionModal}>
                            Cerrar
                        </Button>
                        <Button variant="danger" onClick={deleteAction}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showDeleteProceedingModal} onHide={handleCloseDeleteProceedingModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Desea eliminar el expediente?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeleteProceedingModal}>
                            Cerrar
                        </Button>
                        <Button variant="danger" onClick={deleteProceeding}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal size='lg' show={showViewFileModal} onHide={handleCloseViewFileModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Archivo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PDFViewer document={{
                            base64: actualViewFile
                        }} />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseViewFileModal}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>



            </Container>

            <Modal show={showEditTaskModal} onHide={handleCloseEditTaskModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Modificar tarea</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Encargado</Form.Text>
                        <Row>
                            <Col>
                                <Form.Select className='cover content rows element' defaultValue={editTaskUser} onChange={(event) => handleEditTaskUser(event)}>
                                    <option value="0">Seleccionar...</option>
                                    {lawers.map((p, i) => (
                                        <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>

                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Título</Form.Text>
                        <Form.Control type="text" id="editTaskTitle" defaultValue={editTaskTitle} placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control as="textarea" rows={4} id="editTaskDescription" defaultValue={editTaskDescription} placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" minDate={new Date()} id='' dateFormat="dd/MM/yyyy" selected={editTaskDate} onChange={(d) => handleEditTaskDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Recordar</Form.Text>
                        <Form.Select className='cover content rows element' defaultValue={editTaskReminder} onChange={(event) => handleEditTaskReminder(event)}>
                            <option value="0">No recordar</option>
                            {reminderOptions.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewTaskModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={updateTask}>Confirmar</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showEditActionModal} onHide={handleCloseEditActionModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Modificar actividad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={editActionDate} onChange={(d) => handleEditActionDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Responsable</Form.Text>
                        <Form.Select className='cover content rows element' defaultValue={editActionUser} id="editActionUser">
                            {lawers.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Categoría</Form.Text>
                        <Form.Select className='cover content rows element' id="editActionCategory" defaultValue={editActionCategory}>
                            <option value="0">Seleccionar...</option>
                            {actionCategories.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Gestión</Form.Text>
                        <Form.Control as="textarea" rows={4} defaultValue={editActionDescription} id="editActionDescription" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Foja</Form.Text>
                        <Form.Control type="text" id="editActionFoja" defaultValue={editActionFoja} placeholder="" />
                    </Form.Group>

                    <Form.Group className="mt-2">
                        <Form.Text>Archivo</Form.Text>
                        {editActionFileName ?
                            <div>{editActionFileName}</div>
                            :
                            <Form.Control accept='.pdf, .docx, .xlsx, .png, .jpg, .jpeg, .zip' onChange={(event) => handleEditActionFile(event)} type="file" />
                        }

                    </Form.Group>

                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditActionModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={editAction}>Confirmar</Button>


                </Modal.Footer>
            </Modal>
        </div>
    )
}