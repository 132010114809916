import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Container, Row, Card, Button, } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCoins, faGavel, faPlusCircle, faSchool, faSearch } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { post } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { colors } from '../../../tools/constants';


export default function NewDebtStep2() {

    const PAGE_TITLE = "Nueva deuda";
    const PAGE_ICON = faCoins;

    const [proceedingId, setProceedingId] = React.useState(0);

    const location = useLocation();
    const step2 = location.state;

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `EAC - ${PAGE_TITLE}`;
        console.log(step2)
        createProceeding(step2)
    }, []);

    const createProceeding = async (body) => {
        const req = await post(`/proceedings/debts/new`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            setProceedingId(res.id)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: `${colors().green}` }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: 'white' }}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "white" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <Link to={'/moras'} className='link-primary'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Judiciales
                                    </Link>

                                </td>
                                <td className='content-options rows right'>

                                </td>
                            </tr>
                        </table>

                        <Container className='mt-4 mb-4'>


                            <Container>
                                <Row className='mt-5 mb-5' style={{ textAlign: 'center' }}>
                                    <p style={{ fontSize: 70, color: '#28A745' }}><FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: 8 }} /></p>
                                    <p className='m-0' style={{ fontSize: 20 }}>¡Listo!</p>
                                    <p className='m-0' style={{ fontSize: 20 }}>Expediente creado correctamente</p>
                                    <Link to={`/moras/detalles/?id=${proceedingId}`} className='mt-4 mb-4'>
                                        <Button variant='primary'>Ingresar al expediente<FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 8 }}></FontAwesomeIcon></Button>
                                    </Link>
                                </Row>
                            </Container>

                        </Container>
                    </Card>
                </Row>
            </Container>
        </div>
    )
}