import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, DropdownButton, Form, Col, Modal, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faGavel, faEye, faSearch, faFilter, faDownload, faEllipsisV, faCoins } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { get, post } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { timeSince } from '../../../tools/tools';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { params, colors } from '../../../tools/constants';

export default function CourtFiles() {

    const PAGE_TITLE = "Gestión de deudas";
    const PAGE_ICON = faCoins;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);

    const [selectedLawerFilter, setSelectedLawerFilter] = React.useState(0);
    const [lawers, setLawers] = React.useState([]);
    const [isFilterOn, setIsFilterOn] = React.useState(false);

    const [orderTypes, setOrderTypes] = React.useState(["Ordenar alfabéticamente (A -> Z)", "Ordenar alfabéticamente (Z -> A)", "Ordenar por responsable", "Ordenar por inactividad (menor a mayor)", "Ordenar por inactividad (mayor a menor)", "Ordenar por cliente (A -> Z)", "Ordenar por cliente (Z -> A)", "Ordenar por código de cliente (A -> Z)", "Ordenar por código de cliente (Z -> A)"]);
    const [selectedOrderType, setSelectedOrderType] = React.useState("Ordenar alfabéticamente (A -> Z)");
    const [selectedOnlyMine, setSelectedOnlyMine] = React.useState(false);

    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const handleCloseFilterModal = () => { setShowFilterModal(false); };
    const handleShowFilterModal = () => {
        setShowFilterModal(true);
    };

    const handleOrderType = (event) => {
        setSelectedOrderType(event.target.value);
    };

    const handleOnlyMine = (event) => {
        setSelectedOnlyMine(event.target.checked)
    };

    const handleLawerFilter = (event) => {
        setSelectedLawerFilter(event.target.value);
    };

    const handleFilterButton = (event) => {
        setIsFilterOn(event);
    }

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `EAC - ${PAGE_TITLE}`;

        document.getElementById('textSearch').value = localStorage.getItem('searchChainDT');
        getData();
    }, []);

    const getData = async () => {
        let sort;

        if(localStorage.getItem('userSortExpedientesDT') != null){
            sort = localStorage.getItem('userSortExpedientesDT');
        }else{
            sort = "Ordenar alfabéticamente (A -> Z)";
            localStorage.setItem('userSortExpedientesDT', "Ordenar alfabéticamente (A -> Z)");
        }

        let lf;
        if(localStorage.getItem('userLawerFilterExpedientesDT') != null){
            lf = localStorage.getItem('userLawerFilterExpedientesDT');
        }else{
            lf = 0;
            localStorage.setItem('userLawerFilterExpedientesDT', false);
        }

        await getLawers();
        setSelectedOrderType(sort);
        setSelectedLawerFilter(lf);
        handleSearch(true);
        setupTable();
    }

    const setupTable = function () {
        const cols = ['Carátula', 'Cliente', '', 'Estado', 'Responsable', 'Inicio', 'Inactividad', 'Acciones'];
        setTableColumns(cols);
    };

    const handleSearch = async function (onLoad) {
        let sort;
        let lf;
        if(onLoad){
            if(localStorage.getItem('userSortExpedientesDT') != null){
                sort = localStorage.getItem('userSortExpedientesDT');
            }else{
                sort = "Ordenar alfabéticamente (A -> Z)";
                localStorage.setItem('userSortExpedientesDT', "Ordenar alfabéticamente (A -> Z)");
            }

            if(localStorage.getItem('userLawerFilterExpedientesDT') != null){
                lf = localStorage.getItem('userLawerFilterExpedientesDT');
            }else{
                lf = 0;
                localStorage.setItem('userLawerFilterExpedientesDT', false);
            }

        }else{
            sort = selectedOrderType;
            lf = selectedLawerFilter;

            localStorage.setItem('userSortExpedientesDT', sort);
            localStorage.setItem('userLawerFilterExpedientesDT', lf);
        }

        if(lf == 0){
            handleFilterButton(false);
        }else{
            handleFilterButton(true);
        }

        handleCloseFilterModal()
        const chain = {
            chain: document.getElementById('textSearch').value,
            orderType: sort,
            lawer: lf
        };

        const req = await post(`/proceedings/search/Deuda`, getUserToken(), chain);
        const res = await req.json();

        if (req.status === 200) {
            localStorage.setItem('searchChainDT', document.getElementById('textSearch').value)
            processDebts(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const processDebts = (res) => {
        let result = [];

        for (let r of res) {
            let customer;
            if (r.ProceedingCustomer) {
                if (r.ProceedingCustomer.Business) { 
                    customer = r.ProceedingCustomer.Business.legalName 
                    r.code = <Badge bg='' className='me-2 content-table status' style={{backgroundColor: `${r.ProceedingCustomer.Business.color}`}}>{r.ProceedingCustomer.Business.code}</Badge>;
                }
                if (r.ProceedingCustomer.Person) { 
                    customer = `${r.ProceedingCustomer.Person.surname.toUpperCase()}, ${r.ProceedingCustomer.Person.name}` 
                    r.code = <div></div>
                }
            }
            r.customer = customer;
            r.action = <Link to={`/moras/detalles/?id=${r.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
            r.inactividad = timeSince(r.lastUpdate)

            
            result.push(r);
        }
        setTableRows(result);
    }

    const generateReport = async function () {
        /*localStorage.setItem('userSortExpedientesDT', selectedOrderType);
        localStorage.setItem('userOnlyMineExpedientes', selectedOnlyMine);

        handleCloseFilterModal()
        const chain = {
            chain: "",
            orderType: selectedOrderType,
            onlyMine: selectedOnlyMine
        };*/

        const chain = {
            chain: document.getElementById('textSearch').value,
            orderType: selectedOrderType,
            lawer: selectedLawerFilter
        };

        const req = await post(`/proceedings/debts/report`, getUserToken(), chain);
        const res = await req.json();

        if (req.status === 200) {
            triggerBase64Download(res.file, 'Reporte')
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const getLawers = async function () {
        const req = await get(`/users/lawers`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setLawers(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: '#A0A0A0' }}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='ms-2' type="text" id="textSearch" style={{ width: 250, display: 'inline' }} placeholder="" onChange={() => handleSearch(false)} />
                                    <Button className="ms-2" variant={isFilterOn ? 'warning' : 'primary'} onClick={handleShowFilterModal}><FontAwesomeIcon icon={faFilter} style={{ marginRight: 8 }} />Filtros</Button>
                                </td>
                                <td className='content-options rows right'>
                                
                                    <Link to='/moras/nueva/1'>
                                        <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo expediente</Button>
                                    </Link>
                                    <Dropdown className='ms-2' style={{display: 'inline'}}>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={generateReport}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 8, marginLeft: 2 }} />Descargar reporte</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        </table>

                        {tableRows.length > 0 ?
                            <Container className='pt-3'>
                                <Row className='p-2 mb-2' style={{ background: `${colors().brand_blue}`, color: 'white', borderRadius: 8 }}>
                                <Col xs={1} className='align-center-vertically-v2'>
                                        <div>
                                            
                                        </div>
                                    </Col>
                                    
                                    <Col xs={3} className='align-center-vertically-v2'>
                                        <div>
                                            Cliente / Carátula
                                        </div>
                                    </Col>

                                    <Col xs={2} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            Estado
                                        </div>
                                    </Col>

                                    <Col xs={1} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            Inactividad
                                        </div>
                                    </Col>

                                    <Col xs={2} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            Inicio
                                        </div>
                                    </Col>

                                    <Col xs={2} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            Responsable
                                        </div>
                                    </Col>

                                    <Col xs={1} className='align-center-vertically-v2'>
                                        <div>

                                        </div>
                                    </Col>
                                </Row>
                                {tableRows.map((r, i) => (
                                    <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col xs={1} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                {r.code}
                                            </div>
                                        </Col>

                                        <Col xs={3} className='align-center-vertically'>
                                            <div>
                                                <p className='m-0' style={{ fontSize: 10 }}>{r.ProceedingCustomer ? r.ProceedingCustomer.Business.legalName : ""}</p>
                                                <p className='m-0'>{r.Debt ? r.Debt.name : ''}</p>
                                            </div>
                                        </Col>

                                        <Col xs={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                            <Badge bg={r.Debt.Status.color} className='content-table status'>{r.Debt.Status.name}</Badge>
                                            </div>
                                        </Col>

                                        <Col xs={1} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                {r.inactividad}
                                            </div>
                                        </Col>

                                        <Col xs={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                <Moment format="DD/MM/YYYY">{r.startDate}</Moment>
                                            </div>
                                        </Col>

                                        <Col xs={2} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                {r.Debt ? <span><img className='avatar-10 me-2' style={{ objectFit: 'cover' }} src={`${params().dataUri}${r.Debt.User.avatar}`} />{r.Debt.User.name} {(r.Debt.User.surname).substring(0, 1)}.</span> : ""}
                                            </div>
                                        </Col>

                                        <Col xs={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'right' }}>
                                                {r.action}
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCoins}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se han encontrado expedientes</p>
                                </Row>
                            </Container>

                        }
                    </Card>
                </Row>
            </Container>

            <Modal show={showFilterModal} onHide={handleCloseFilterModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Filtros</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Orden</Form.Text>
                        <Form.Select className='cover content rows element' id="" value={selectedOrderType} onChange={(event) => handleOrderType(event)}>
                            {orderTypes.map((p, i) => (
                                <option key={i} id={i} value={p}>{p}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Encargado</Form.Text>
                        <Form.Select className='cover content rows element' id="" defaultValue={selectedLawerFilter} onChange={(event) => handleLawerFilter(event)}>
                            <option key={0} id={0} value={0}>Todos</option>
                            {lawers.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseFilterModal}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={() => handleSearch(false)}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}