import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Form, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faFile, faSearch } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { get } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { colors } from '../../../tools/constants';

export default function CourtFiles() {

    const PAGE_TITLE = "Archivos";
    const PAGE_ICON = faFile;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `EAC - ${PAGE_TITLE}`;
        getCourtFiles();
    }, []);

    const setupTable = function () {
        const cols = ['Nombre', 'Cliente', 'Inicio', 'Acciones'];
        setTableColumns(cols);
    };

    const getCourtFiles = async function () {
        const req = await get('/proceedings/archived', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            let result = [];

            for (let r of res) {
                //r.school = <Badge bg='' className='content-table status' style={{backgroundColor: `${r.debt.school.color}`}}>{r.debt.school.code}</Badge>;
                //r.status.name = <Badge bg='' className='content-table status' style={{backgroundColor: `${r.status.color}`}}>{r.status.name}</Badge>;
                if (r.Status.name == "Judicial") {
                    r.action = <Link to={`/expedientes/detalles/?id=${r.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
                } else if (r.Status.name == "Deuda") {
                    r.action = <Link to={`/moras/detalles/?id=${r.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
                }



                //r.inactividad = parseInt((moment.duration(moment().diff(r.updatedAt))).asDays()) + (parseInt((moment.duration(moment().diff(r.updatedAt))).asDays()) == 1 ? ' día' : ' días');
                result.push(r);
            }
            setTableRows(result);
            setupTable();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: '#A0A0A0' }}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='ms-2' type="text" id="textFee" style={{ width: 250, display: 'inline' }} placeholder="" />
                                </td>
                                <td className='content-options rows right'>

                                </td>
                            </tr>
                        </table>

                        {tableRows.length > 0 ?
                            <Container className='pt-3 mb-3'>
                                <div className='d-none d-sm-block'>
                                    <Row className=' p-2 mb-2' style={{ background: `${colors().brand_blue}`, color: 'white', borderRadius: 8 }}>
                                        <Col sm={9} className='align-center-vertically-v2'>
                                            <div>
                                                Cliente / Carátula
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Inicio
                                            </div>
                                        </Col>
                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {tableRows.map((r, i) => (
                                    <div>
                                        <div className='d-none d-sm-block'>
                                            <Row className='p-2 mb-2' style={{ background: r.income ? '#d8eddd' : '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={9} className='align-center-vertically'>
                                                    <div>
                                                    <p className='m-0' style={{ fontSize: 10 }}>{r.customer}</p>
                                                    <p className='m-0'>{r.name.toUpperCase()}</p>
                                                    </div>
                                                </Col>

                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <p className='m-0'><Moment format="DD/MM/YYYY">{r.createdAt}</Moment></p>
                                                    </div>

                                                </Col>

                                                <Col xs={1} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <p className='m-0'>{r.action}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>


                                    </div>

                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={PAGE_ICON}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron expedientes archivados</p>
                                </Row>
                            </Container>

                        }

                       

                    </Card>
                </Row>
            </Container>
        </div>
    )
}