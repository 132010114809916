import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, Badge, Modal, Form, ListGroup, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCoins, faEye, faIndustry, faPlusCircle, faSchool, faSearch, faAddressBook, faTrash, faPen, faUser, faCheck, faIdCard, faPhoneAlt, faEnvelope, faMapMarkerAlt, faInfoCircle, faAddressCard, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import SubNavBarC from "../navbar/SubNavbarCustomers";
import SubNavBarD from "../navbar/SubNavbarDefendants";
import { get, post, del, put } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { pn } from '../../../tools/chains';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { params, colors } from '../../../tools/constants'


export default function BusinessDetails(props) {

    const PAGE_TITLE = "Persona jurídica";
    const PAGE_ICON = faIndustry;
    const [provinces, setProvinces] = React.useState([]);
    const [selectedProvince, setSelectedProvince] = React.useState("");
    const [color, setColor] = React.useState("");
    const [name, setName] = React.useState("");

    const [businessContacts, setBusinessContacts] = React.useState([]);
    const [tableColumns, setTableColumns] = React.useState([]);
    const [showNewContact, setShowNewContact] = React.useState(false);
    const [showNewContactData, setShowNewContactData] = React.useState(false);
    const [showSureDelete, setShowSureDelete] = React.useState(false);
    const [showSureEditCustomer, setShowSureEditCustomer] = React.useState(false);
    const [newContactName, setNewContactName] = React.useState('');
    const [currentContact, setCurrentContact] = React.useState('');
    const [currentContactName, setCurrentContactName] = React.useState('');
    const [currentContactType, setCurrentContactType] = React.useState('Seleccionar...');
    const [newContactData, setNewContactData] = React.useState('');
    const [isEditing, setIsEditing] = React.useState(false);
    const [customerBalanceCol, setCustomerBalanceCol] = React.useState([]);
    const [customerBalance, setCustomerBalance] = React.useState([]);
    const [customerBalanceIndicators, setCustomerBalanceIndicators] = React.useState([]);

    const [showDeleteCustomerBalanceModal, setShowDeleteCustomerBalanceModal] = React.useState(false);
    const [selectedCustomerBalanceId, setSelectedCustomerBalanceId] = React.useState("0");
    const handleCloseDeleteCustomerBalanceModal = () => { setShowDeleteCustomerBalanceModal(false); };
    const handleShowDeleteCustomerBalanceModal = (id) => {
        setSelectedCustomerBalanceId(id)
        setShowDeleteCustomerBalanceModal(true);
    }

    const handleIsEditing = () => {
        setIsEditing(!isEditing)
    };

    const handleCloseNewContact = () => setShowNewContact(false);
    const handleShowNewContact = () => setShowNewContact(true);
    const handleCloseNewContactData = () => setShowNewContactData(false);
    const handleShowNewContactData = (contactId) => {
        setCurrentContact(contactId);
        setShowNewContactData(true);
    };
    const handleCloseSureDelete = () => setShowSureDelete(false);
    const handleShowSureDelete = (name, id) => {
        setCurrentContactName(name);
        setCurrentContact(id);
        setShowSureDelete(true);
    };

    const [showDeleteBusinessModal, setShowDeleteBusinessModal] = React.useState(false);
    const handleCloseDeleteBusinessModal = () => { setShowDeleteBusinessModal(false); };
    const handleShowDeleteBusinessModal = () => {
        setShowDeleteBusinessModal(true);
    }
    const handleCloseSureEditCustomer = () => setShowSureEditCustomer(false);
    const handleShowSureEditCustomer = () => setShowSureEditCustomer(true);


    const handleProvince = (event) => {
        setSelectedProvince(event.target.value);
    };

    const handleColor = (event) => {
        setColor(event.target.value);
    };

    const handleIdentifier = (event) => {
        getIdentifier(event.target.value)
    };

    const handleNewContactName = (event) => {
        setNewContactName(event.target.value);
    };

    const handleNewContactData = (event) => {
        setNewContactData(event.target.value);
    };

    const createContact = async function () {
        setShowNewContact(false);

        const contact = {
            businessId: getBusinessId(),
            name: newContactName,
        };

        const req = await post(`/${props.customers ? 'customers' : 'defendants'}/business/contacts/`, getUserToken(), contact);
        const res = await req.json();

        if (req.status === 201) {
            getContacts();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `EAC - ${PAGE_TITLE}`;

        getBusiness();
        getProvinces();
        getContacts();
        setupTable();
        getCustomerBalanceById(getBusinessId())
    }, []);

    const getProvinces = async function () {
        const req = await get('/others/provinces', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setProvinces(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getIdentifier = async function (data) {
        const chain = {
            chain: data
        }
        const req = await post('/others/identifier', getUserToken(), chain);
        const res = await req.json();

        if (req.status === 200) {
            document.getElementById('textIdentifier').value = res.chain;
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const getBusinessId = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const getContacts = async function () {
        const req = await get(`/${props.customers ? 'customers' : 'defendants'}/business/contacts/${getBusinessId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processContacts(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processContacts = function (data) {
        let result = [];
        let aux;
        let icon;
        let cdata = [];

        for (let c of data) {
            aux = null;

            for (let i = 0; i < c.ContactData.length; i++) {
                switch (c.ContactData[i].param) {
                    case "PHON":
                        icon = <FontAwesomeIcon icon={faPhoneAlt}></FontAwesomeIcon>;
                        break;
                    case "WHAT":
                        icon = <FontAwesomeIcon icon={faWhatsapp}></FontAwesomeIcon>;
                        break;
                    case "EMAI":
                        icon = <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>;
                        break;
                    case "ADDR":
                        icon = <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>;
                        break;
                    case "POSI":
                        icon = <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>;
                        break;
                    case "DNIN":
                        icon = <FontAwesomeIcon icon={faIdCard}></FontAwesomeIcon>;
                        break;
                    case "OTHE":
                        icon = <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>;
                        break;
                    default:
                        break;
                }
                cdata.push({
                    icon: icon,
                    data: c.ContactData[i].data,
                    id: c.ContactData[i].id
                })
            }
            c.data = cdata;
            c.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleShowNewContactData(c.id)}><FontAwesomeIcon icon={faAddressCard} style={{ marginRight: 10, marginLeft: 2 }} />Agregar datos</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleShowSureDelete(c.name, c.id)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )

            result.push(c);
        }
        setBusinessContacts(result);
    }

    const getBusiness = async function () {
        const req = await get(`/${props.customers ? 'customers' : 'defendants'}/business/byId/${getBusinessId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processBusiness(res)
        } else if (req.status === 403) {
            logout();
        } else {
            console.log(res)
            alert(res.message);
        }
    }

    const setupTable = function () {
        setCustomerBalanceCol(['Fecha', 'Carátula', 'A transferir', 'Transferido', ''])
    }

    const changeCurrentContactType = function (type) {
        setCurrentContactType(type);
        document.getElementById('currentContactType').innerHTML = type;
    }

    const createContactData = async function () {
        setShowNewContactData(false);

        let param;

        switch (currentContactType) {
            case "Teléfono":
                param = "PHON";
                break;
            case "Correo electrónico":
                param = "EMAI";
                break;
            case "Dirección":
                param = "ADDR";
                break;
            case "Cargo":
                param = "POSI";
                break;
            case "DNI":
                param = "DNIN";
                break;
            case "Otros":
                param = "OTHE";
                break;
            default:
                break;
        }

        const contact = {
            contactId: currentContact,
            param: param,
            data: newContactData
        };

        const req = await post(`/${props.customers ? 'customers' : 'defendants'}/business/contacts/data`, getUserToken(), contact);
        const res = await req.json();

        if (req.status === 201) {
            getContacts();
        } else {
            alert(res.message);
        }
    }

    const deleteContact = async function () {
        setShowSureDelete(false);

        const req = await del(`/${props.customers ? 'customers' : 'defendants'}/business/contacts/${getBusinessId()}/${currentContact}`, getUserToken());
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getContacts();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processBusiness = async function (data) {
        setName(`${data.legalName}`)
        document.getElementById('textLegalName').value = data.legalName
        document.getElementById('textCuit').value = data.cuit
        document.getElementById('textOrder').value = data.educationalNetwork != null ? data.educationalNetwork.name : "";
        document.getElementById('textIdentifier').value = data.code
        document.getElementById('textAddress').value = data.address
        document.getElementById('textCity').value = data.city
        document.getElementById('textPostalCode').value = data.postalCode
        document.getElementById('textProvince').value = data.province
        document.getElementById('colorIdentifier').value = data.color

        if (data.province != null) {
            setSelectedProvince(data.province)
        }
    }

    const editBusiness = async function () {
        const data = {
            id: getBusinessId(),
            cuit: document.getElementById('textCuit').value,
            legalName: document.getElementById('textLegalName').value,
            address: document.getElementById('textAddress').value,
            city: document.getElementById('textCity').value,
            province: selectedProvince,
            postalCode: document.getElementById('textPostalCode').value,
            order: document.getElementById('textOrder').value.length > 1 ? document.getElementById('textOrder').value : null,
            code: document.getElementById('textIdentifier').value,
            color: document.getElementById('colorIdentifier').value,
        };

        const req = await put(`/${props.customers ? 'customers' : 'defendants'}/business`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            setIsEditing(false)
        } else if (req.status === 403) {
            logout();
        } else {
            console.log(res)
            alert(res.message);
        }
    }

    const deleteBusiness = async function () {
        const data = {
            businessId: getBusinessId(),
        }
        const req = await del(`/business/`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = `/${props.customers ? 'clientes/empresas' : 'demandados/empresas'}`
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCustomerBalanceById = async (businessId) => {
        const req = await get(`/administration/balance/${businessId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processCustomerBalanceById(res)
            getCurrentAccountIndicators(businessId)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processCustomerBalanceById = (data) => {
        let result = [];
        for (let e of data) {
            e.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleShowDeleteCustomerBalanceModal(e.id, e.description)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
            if (e.Proceeding) {
                e.name = e.Proceeding.Debt.name;
            } else {
                e.name = e.description;
                e.description = "Liquidación";
            }

            if (e.executed) {
                e.exe = <Badge className='' bg='success'>Si</Badge>;
                e.exeDateFormatted = moment(new Date(e.exeDate)).format("DD/MM/YYYY");
            } else {
                e.exe = <Badge className='' bg='danger'>No</Badge>;
                e.exeDateFormatted = "";
            }

            result.push(e)
        }

        setCustomerBalance(result)
    }

    const deleteCustomerBalance = async () => {
        const req = await del(`/administration/balance`, getUserToken(), { id: selectedCustomerBalanceId });
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCurrentAccountIndicators = async (businessId) => {
        const req = await get(`/administration/balance/${businessId}/indicators`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setCustomerBalanceIndicators(res)
        } else if (req.status === 503) {
            logout();
        } else {
            alert(res.message);
        }
    }


    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />
            {props.customers ? <SubNavBarC /> : <SubNavBarD />}

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 25, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{name}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <Link to={`/${props.customers ? 'clientes' : 'demandados'}/empresas`} className='link-primary'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Empresas
                                    </Link>


                                </td>
                                <td className='content-options rows right'>
                                    <Button variant="warning" onClick={handleIsEditing}><FontAwesomeIcon icon={faPen} style={{ marginRight: 8 }} />Modificar</Button>
                                    <Button className='ms-2' variant="danger" onClick={handleShowDeleteBusinessModal}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 8 }} />Eliminar</Button>
                                </td>
                            </tr>
                        </table>

                        <Container className='mt-4 mb-4'>
                            <Row>
                                <Col xs={6}>
                                    <table className='w-100'>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold', width: 170 }}>Razón social</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textLegalName" readOnly={!isEditing} placeholder="" onChange={(event) => handleIdentifier(event)} />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold', width: 170 }}>CUIT</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textCuit" readOnly={!isEditing} placeholder="" />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Orden</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textOrder" readOnly={!isEditing} placeholder="" />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Identificador</td>
                                            <td className='pt-2' >
                                                <Row>
                                                    <Col>
                                                        <Form.Control type="text" id="textIdentifier" readOnly={!isEditing} placeholder="" />
                                                    </Col>
                                                    <Col>
                                                        <Form.Control
                                                            type="color"
                                                            id="colorIdentifier"
                                                            defaultValue="#563d7c"
                                                            title="Choose your color"
                                                            disabled={!isEditing}
                                                            onChange={(event) => handleColor(event)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Dirección</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textAddress" readOnly={!isEditing} placeholder="" />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Localidad</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textCity" readOnly={!isEditing} placeholder="" />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Provincia</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Select className='cover content rows element' id="textProvince" disabled={!isEditing} onChange={(event) => handleProvince(event)}>
                                                        <option value="0">Seleccionar...</option>
                                                        {provinces.map((p, i) => (
                                                            <option key={i} id={i} value={p.name}>{p.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Código postal</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textPostalCode" readOnly={!isEditing} placeholder="" />
                                                </Form.Group>
                                            </td>
                                        </tr>

                                    </table>
                                </Col>
                                <Col xs={6}>
                                    <table className='w-100'>


                                    </table>
                                </Col>
                            </Row>

                            {isEditing ?
                                <div className='mt-5' style={{ textAlign: 'right' }}>
                                    <Button variant="success" onClick={editBusiness}><FontAwesomeIcon icon={faCheck} style={{ marginRight: 8 }} />Confirmar cambios</Button>
                                </div>

                                :
                                <div></div>
                            }

                        </Container>
                    </Card>
                </Row>

            </Container>

            <Container>
                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>

                                    <span>Contactos</span>
                                </td>
                                <td className='content-options rows right'>
                                    <Button variant="success" onClick={handleShowNewContact}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo contacto</Button>
                                </td>

                            </tr>
                        </table>

                        <Container className='pt-3 mb-3'>
                            {businessContacts.length > 0 ?
                                <div>
                                    {businessContacts.map((r, i) => (
                                        <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col className='align-center-vertically-v2' xs={3}>
                                                {r.name}
                                            </Col>
                                            <Col className='' xs={7} md={8}>
                                                {r.data.map((d, i) => (
                                                    <Badge bg='secondary' className='content-table status me-2'><span className='me-1'>{d.icon}</span> {d.data}</Badge>
                                                ))}

                                            </Col>
                                            <Col className='align-center-vertically-v2' style={{ textAlign: 'right' }} xs={2} md={1}>
                                                {r.action}
                                            </Col>

                                        </Row>
                                    ))}
                                </div>
                                :
                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faAddressBook}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No hay contactos</p>
                                </Row>
                            }
                        </Container>

                    </Card>
                </Row>

                {
                    props.customers ?

                        <Row>
                            <Card className='cards content mb-5'>
                                <table className='content-options content'>
                                    <tr>
                                        <td className='content-options rows left'>
                                            Balance
                                        </td>
                                        <td className='content-options rows right'>

                                        </td>
                                    </tr>
                                </table>


                                {
                                    customerBalance.length > 0 ?
                                        <div>
                                            <Container>
                                                <Row className='mt-4 mb-4'>

                                                    {customerBalanceIndicators.map((ind, i) => (
                                                        <Col key={i} xs={3}>
                                                            <Card className={`statusCard ${ind.color} administration`}>
                                                                <Card.Body>
                                                                    <Card.Title>{ind.name}</Card.Title>
                                                                    <Card.Text>
                                                                        <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>{ind.value}</p>
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    ))}


                                                </Row>
                                            </Container>

                                            {customerBalance.length > 0 ?
                                                <Container className='pt-3 mb-3'>
                                                    <div className='d-none d-sm-block'>
                                                        <Row className=' p-2 mb-2' style={{ background: `${colors().brand_blue}`, color: 'white', borderRadius: 8 }}>
                                                            <Col sm={1} className='align-center-vertically-v2'>
                                                                <div>
                                                                    Fecha
                                                                </div>
                                                            </Col>

                                                            <Col sm={2} className='align-center-vertically-v2' >
                                                                <div>
                                                                    Carátula
                                                                </div>
                                                            </Col>

                                                            <Col sm={3} className='align-center-vertically-v2' >
                                                                <div>
                                                                    Descripción
                                                                </div>
                                                            </Col>

                                                            <Col sm={2} className='align-center-vertically-v2' >
                                                                <div style={{ textAlign: 'center' }}>
                                                                    Importe
                                                                </div>
                                                            </Col>

                                                            <Col sm={2} className='align-center-vertically-v2'>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    Honorarios
                                                                </div>
                                                            </Col>
                                                            <Col sm={1} className='align-center-vertically-v2'>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    Liq.
                                                                </div>
                                                            </Col>
                                                            <Col sm={1} className='align-center-vertically-v2'>
                                                                <div style={{ textAlign: 'center' }}>

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    {customerBalance.map((r, i) => (
                                                        <div>
                                                            <div className='d-none d-sm-block'>
                                                                <Row className='p-2 mb-2' style={{ background: r.income ? '#d8eddd' : '#F0F0F0', borderRadius: 8 }}>
                                                                    <Col xs={1} className='align-center-vertically'>
                                                                        <Moment format="DD/MM/YY">{r.date}</Moment>

                                                                    </Col>
                                                                    <Col xs={2} className='align-center-vertically-v2'>
                                                                        <div>

                                                                            <p className='m-0'>{r.name}</p>
                                                                        </div>

                                                                    </Col>
                                                                    <Col xs={3} className='align-center-vertically-v2'>
                                                                        <div>

                                                                            <p className='m-0'>{r.description}</p>
                                                                        </div>

                                                                    </Col>
                                                                    <Col xs={2} className='align-center-vertically-v2'>
                                                                        <div style={{ textAlign: 'center' }}>
                                                                            {r.income == false ? `$${pn(r.customer)}` : ""}
                                                                            {r.income == true ? `$${pn(r.amount * -1)}` : ""}
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={2} className='align-center-vertically-v2'>
                                                                        <div style={{ textAlign: 'center' }}>
                                                                            {r.income == true ? `$${pn(r.fee)}` : ""}
                                                                        </div>
                                                                    </Col>

                                                                    <Col xs={1} className='align-center-vertically-v2'>
                                                                        <div style={{ textAlign: 'center' }}>
                                                                            <p className='m-0'>{r.exe}</p>
                                                                            <p className='mt-1 mb-0' style={{ fontSize: 12 }}>{r.exeDateFormatted}</p>
                                                                        </div>
                                                                    </Col>

                                                                    <Col xs={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                                        <div style={{ textAlign: 'right' }}>
                                                                            {r.action}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>


                                                        </div>

                                                    ))}
                                                </Container>
                                                :
                                                <Container className='mt-5 mb-5'>

                                                    <Row style={{ height: 300, textAlign: "center" }}>
                                                        <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCoins}></FontAwesomeIcon></p>
                                                        <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron movimientos</p>
                                                    </Row>
                                                </Container>

                                            }

                                        </div>

                                        :

                                        <Row style={{ height: 300, textAlign: "center" }}>
                                            <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCoins}></FontAwesomeIcon></p>
                                            <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No hay operaciones</p>
                                        </Row>
                                }


                            </Card>
                        </Row>

                        : <div></div>
                }



                <Modal show={showDeleteBusinessModal} onHide={handleCloseDeleteBusinessModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Desea eliminar a la persona jurídica?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeleteBusinessModal}>
                            Cerrar
                        </Button>
                        <Button variant="danger" onClick={deleteBusiness}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showNewContact} centered onHide={handleCloseNewContact}>
                    <Modal.Header closeButtonNewContact>
                        <Modal.Title>Nuevo contacto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Nombre y apellido</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Juan Pérez"
                                        autoFocus
                                        onChange={(event) => handleNewContactName(event)}
                                    />
                                </Form.Group>
                            </Row>


                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseNewContact}>
                            Cancelar
                        </Button>
                        <Button variant="success" onClick={createContact}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showNewContactData} centered onHide={handleCloseNewContactData}>
                    <Modal.Header closeButtonNewContact>
                        <Modal.Title>Añadir datos de contacto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Row>
                                        <Col xs={5}>
                                            <Dropdown className='w-100'>
                                                <Dropdown.Toggle variant="primary" className='w-100' id="dropdown-basic">
                                                    <span id='currentContactType'>Seleccionar...</span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='w-100'>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Teléfono")}>Teléfono</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Correo electrónico")}>Correo electrónico</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Dirección")}>Dirección</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("DNI")}>DNI</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Cargo")}>Cargo</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Otro")}>Otro</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>

                                        <Col>
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                autoFocus
                                                onChange={(event) => handleNewContactData(event)}
                                            />
                                        </Col>
                                    </Row>

                                </Form.Group>
                            </Row>


                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseNewContactData}>
                            Cancelar
                        </Button>
                        <Button variant="success" onClick={createContactData}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showSureDelete} centered onHide={handleCloseSureDelete}>
                    <Modal.Header closeButtonSureDelete>
                        <Modal.Title>Eliminar contacto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Está seguro de eliminar al contacto {currentContactName}?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseSureDelete}>
                            Cancelar
                        </Button>
                        <Button variant="success" onClick={deleteContact}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showDeleteCustomerBalanceModal} onHide={handleCloseDeleteCustomerBalanceModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Desea eliminar la operación? Tené en cuenta que no se eliminará el honorario de la cuenta corriente
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeleteCustomerBalanceModal}>
                            Cerrar
                        </Button>
                        <Button variant="danger" onClick={deleteCustomerBalance}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </div>
    )
}