import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Form, Dropdown, DropdownButton, Badge, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle, faUsers, faEye, faUser, faSearch} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import SubNavBarC from "../navbar/SubNavbarCustomers";
import SubNavBarD from "../navbar/SubNavbarDefendants";
import {get, post} from '../../../controllers/endpoints.controller';
import {getUserToken, logout} from '../../../controllers/user.controller';
import { colors } from '../../../tools/constants';

export default function Persons(props){

    const PAGE_TITLE = "Personas físicas";
    const PAGE_ICON = faUser;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);

    
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `EAC - ${PAGE_TITLE}`;
        getPersons();
    }, []);

    const handleSearch = async function(event){
        const chain = {
            chain: event.target.value
        };
        const req = await post(`/${props.customers ? 'customers' : 'defendants'}/persons/search`, getUserToken(), chain);
        const res = await req.json();

        if(req.status === 200){
            console.log(res)
            processPersons(res)
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
            console.log(res)
        }
    }


    const getPersons = async function(status){
        const req = await get(`/${props.customers ? 'customers' : 'defendants'}/persons/`, getUserToken());
        const res = await req.json();

        if(req.status === 200){
            processPersons(res)
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    const processPersons = async function(data){
        let result = [];
        for(let r of data){
            //r.debts = <div><Badge bg='' className='content-table status' style={{backgroundColor: `${status['Activo']}`}}>{r.debts.active}</Badge><Badge bg='' className='content-table status ms-2' style={{backgroundColor: `${status['En juicio']}`}}>{r.debts.onCourt}</Badge><Badge bg='' className='content-table status ms-2' style={{backgroundColor: `${status['Cerrado']}`}}>{r.debts.closed}</Badge></div>
            r.action = <Link to={`/${props.customers ? 'clientes' : 'demandados'}/personas/detalles/?id=${r.id}`}><Button className='' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
            result.push(r);
        }
        setTableRows(result);
        setupTable();
    }

    const setupTable = function(){
        const cols = ['Nombre', 'DNI', 'Correo electrónico', 'Teléfono', 'Celular', 'Acciones'];
        setTableColumns(cols);
    };

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>
            {props.customers ? <SubNavBarC/> : <SubNavBarD/>}

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                    <p className='m-0' style={{color: '#A0A0A0', fontSize: 25, fontWeight: 300}}>{`${props.customers ? 'Clientes' : 'Contrarios'}`}</p>
                                    <p className='m-0' style={{color: '#A0A0A0', fontSize: 45, fontWeight: 300}}>{PAGE_TITLE}</p>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='ms-2' type="text" id="textFee" style={{width: 250, display: 'inline'}} placeholder="" onChange = {(event) => handleSearch(event)} />
                                </td>
                                <td className='content-options rows right'>
                                    <Link to={`/${props.customers ? 'clientes' : 'demandados'}/personas/nueva`}>
                                        <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 8}} />Nueva persona física</Button>
                                    </Link>
                                    
                                </td>
                            </tr>
                        </table>

                        {tableRows.length > 0 ?
                            <Container className='pt-3 mb-3'>
                                <div className='d-none d-sm-block'>
                                    <Row className=' p-2 mb-2' style={{ background: `${colors().brand_blue}`, color: 'white', borderRadius: 8 }}>
                                        <Col sm={4} className='align-center-vertically-v2'>
                                            <div>
                                                Nombre
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div>
                                                DNI
                                            </div>
                                        </Col>

                                        <Col sm={3} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                                Correo
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Teléfono
                                            </div>
                                        </Col>


                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {tableRows.map((r, i) => (
                                    <div>
                                        <div className='d-none d-sm-block'>
                                            <Row className='p-2 mb-2' style={{ background: r.income ? '#d8eddd' : '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={4} className='align-center-vertically'>
                                                <p className='m-0'>{r.surname.toUpperCase()}, {r.name}</p>
                                                </Col>
                                                <Col xs={2} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                        <p className='m-0'>{r.dni}</p>
                                                    </div>

                                                </Col>
                                                <Col xs={3} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                        <p className='m-0'>{r.email}</p>
                                                    </div>

                                                </Col>
                                                

                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <p className='m-0'>{r.phone}</p>
                                                        <p className='m-0'>{r.mobile}</p>
                                                    </div>
                                                </Col>

                                                <Col xs={1} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <p className='m-0'>{r.action}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>


                                    </div>

                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={PAGE_ICON}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron personas físicas</p>
                                </Row>
                            </Container>

                        }
                    </Card>
                </Row>
            </Container>
        </div>
    )
}