import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Col, Card, Badge, Button, Table, Dropdown, Form, Modal, ToggleButton, Alert } from "react-bootstrap";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import listPlugin from '@fullcalendar/list';
import Moment from 'react-moment';
import moment from 'moment'
import 'moment/locale/es-mx';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { params } from '../../../tools/constants'

import { get, post, del, put } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { faBell, faCheck, faCoins, faEllipsisV, faEye, faGavel, faPlusCircle, faTasks, faTrash } from '@fortawesome/free-solid-svg-icons';

import NavBar from "../navbar/Navbar";
import { processDate, processDateCalendar, processDateEvents, timeDue, timeSince, timeDueWithBadge } from '../../../tools/tools';



export default function Home() {
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    const [tasks, setTasks] = React.useState([]);
    const [error, setError] = React.useState(<div></div>);
    const [indicators, setIndicators] = React.useState([]);
    const [proceedings, setProceedings] = React.useState([]);
    const [lawers, setLawers] = React.useState([]);
    const [lawer, setLawer] = React.useState({});
    const [showNewTaskModal, setShowNewTaskModal] = React.useState(false);
    const [reminderOptions, setReminderOptions] = React.useState([]);
    const [newTaskProceedingId, setNewTaskProceedingId] = React.useState(null);
    const [newTaskDate, setNewTaskDate] = React.useState(null);
    const [newTaskReminder, setNewTaskReminder] = React.useState(null);
    const [newTaskNotificationChecked, setNewTaskNotificationChecked] = React.useState(false);
    const [newTaskUser, setNewTaskUser] = React.useState({});
    const [selectedLawer, setSelectedLawer] = React.useState({});
    const handleCloseNewTaskModal = () => { setShowNewTaskModal(false); };
    const handleShowNewTaskModal = () => {
        setError()
        getReminderOptions();
        getLawers();
        getAllProceedings();
        setNewTaskNotificationChecked(false);
        setShowNewTaskModal(true);
    };
    const [showNewNotificationModal, setShowNewNotificationModal] = React.useState(false);
    const handleCloseNewNotificationModal = () => { setShowNewNotificationModal(false); };
    const handleShowNewNotificationModal = () => {
        setError()
        getLawers();
        getAllProceedings();
        setShowNewNotificationModal(true);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto']
            }
        });

        document.title = "EAC - Inicio";
        registerLocale("es", { ...es, options: { ...es.options, weekStartsOn: 0 } });
        //document.getElementsByClassName('fc-today-button')[0].innerHTML = 'Hoy';
        setupOpTable();

        init();
    }, []);

    const init = function () {
        //getEvents();
        //getAllProceedings()
        getCourtFiles();
        getTasks()
        getIndicators();
    }

    const handleNewTaskUser = (event) => {
        setNewTaskUser(event.target.value)
    };

    const handleNewTaskNotification = (event) => {
        setNewTaskNotificationChecked(event)
    };

    const handleNewTaskProceeding = (event) => {
        setNewTaskProceedingId(event.target.value)
    };


    const handleNewTaskReminder = (event) => {
        setNewTaskReminder(event.target.value)
    };

    const handleNewTaskDate = (event) => {
        setNewTaskDate(event);
    };


    const setupOpTable = function () {
        const cols = ['Código', 'Cliente', 'Estado', ''];
        setTableColumns(cols);

    };

    const getTasks = async function () {
        const req = await get('/tasks/home', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processTasks(res)
            //processEvents(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processTasks = function (data) {
        let result = [];
        let aux;

        for (let t of data) {
            if (t.Proceeding) {
                switch (t.Proceeding.Status.name) {
                    case "Deuda":
                        aux = `GDD - ${t.Proceeding.Debt.name}`;
                        break;
                    case "Judicial":
                        aux = `Expediente - ${t.Proceeding.CourtFile.name}`;
                        break;
                }
            } else {
                aux = "";
            }

            if (t.Proceeding.Status.name == "Judicial") {
                t.proceedingUrl = `/expedientes/detalles/?id=${t.Proceeding.id}`
            } else if (t.Proceeding.Status.name == "Deuda") {
                t.proceedingUrl = `/moras/detalles/?id=${t.Proceeding.id}`
            }

            t.proceedingName = aux;
            result.push(t);
        }

        setTasks(result);
    }

    const getIndicators = async function () {
        const req = await get(`/indicators/home`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setIndicators(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }


    const getLawers = async function () {
        const req = await get(`/users/lawers`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setLawers(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getAllProceedings = async function () {
        const req = await get(`/proceedings/all/home`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setProceedings(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getReminderOptions = async function () {
        const req = await get(`/tasks/reminderOptions/`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setReminderOptions(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processOperations = function (operations) {
        let result = [];
        for (let p of operations) {
            p.status = <Badge bg='' className='content-table status' style={{ backgroundColor: `#${p.Status.color}` }}>{p.Status.name}</Badge>
            p.inactividad = timeSince(p.updatedAt)
            p.action = <Link to={`/operaciones/detalles/?id=${p.id}`}><Button className='buttonBlue' variant="primary">Ver detalles</Button></Link>
            result.push(p);
        }
        setTableRows(result);
    }

    const getCourtFiles = async function () {
        const req = await get('/proceedings/all/home', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            let result = [];

            for (let r of res) {
                //r.school = <Badge bg='' className='content-table status' style={{backgroundColor: `${r.debt.school.color}`}}>{r.debt.school.code}</Badge>;
                //r.status.name = <Badge bg='' className='content-table status' style={{backgroundColor: `${r.status.color}`}}>{r.status.name}</Badge>;
                if (r.Status.name == "Judicial") {
                    r.name = r.CourtFile.name;
                    r.type = <FontAwesomeIcon icon={faGavel}></FontAwesomeIcon>
                } else if (r.Status.name == "Deuda") {
                    r.name = r.Debt.name;
                    r.type = <FontAwesomeIcon icon={faCoins}></FontAwesomeIcon>
                }

                r.action = <Link to={`/${r.CourtFile ? 'expedientes' : 'moras'}/detalles/?id=${r.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
                r.inactividad = timeSince(r.lastUpdate)
                result.push(r);
            }
            setTableRows(result);
            setupTable();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const setupTable = function () {
        const cols = ['', 'Carátula', 'Inactividad', ''];
        setTableColumns(cols);
    };

    const setTaskAsCompleted = async function (taskId, proceedingId) {
        const req = await put(`/tasks/byProceedingId/asCompleted/`, getUserToken(), { taskId: taskId, proceedingId: proceedingId });
        const res = await req.json();

        if (req.status === 200) {
            getTasks();
            getIndicators()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createTask = async function () {
        if (checkNewTask()) {
            const task = {
                proceedingId: newTaskProceedingId != '0' ? newTaskProceedingId : null,
                userId: newTaskUser,
                dueDate: newTaskDate,
                alertId: newTaskReminder,
                title: document.getElementById("newTaskTitle").value,
                description: document.getElementById("newTaskDescription").value,
                isEvent: true,
                sendNotification: newTaskNotificationChecked
            };

            const req = await post(`/tasks/`, getUserToken(), task);
            const res = await req.json();

            if (req.status === 201) {
                getTasks();
                handleCloseNewTaskModal();
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const createNotification = async function () {
        if (checkNewNotification()) {
            const task = {
                proceedingId: newTaskProceedingId != '0' ? newTaskProceedingId : null,
                userId: newTaskUser,
                title: document.getElementById("newNotificationTitle").value,
                body: document.getElementById("newNotificationDescription").value
            };

            const req = await post(`/notifications/`, getUserToken(), task);
            const res = await req.json();

            if (req.status === 201) {
                setNewTaskProceedingId(null)
                setNewTaskUser({})
                handleCloseNewNotificationModal();
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const checkNewTask = () => {
        let result = true;

        if (newTaskUser == '0') {
            result = false;
            showError('No se ha asignado un encargado')
        } else if (document.getElementById("newTaskTitle").value.length <= 1) {
            result = false;
            showError('No se ha escrito el título')
        }

        return result;
    }

    const checkNewNotification = () => {
        let result = true;

        if (newTaskUser == '0') {
            result = false;
            showError('No se ha asignado un encargado')
        } else if (document.getElementById("newNotificationTitle").value.length <= 1) {
            result = false;
            showError('No se ha escrito el título')
        }

        return result;
    }



    const showError = (title) => {
        setError(
            <Alert className='mt-3 mb-0' key='danger' variant='danger'>
                {title}
            </Alert>
        )
    }

    const goToProceeding = (url) => {
        window.location.href = url;
    }

    return (
        <div className='p-0 mt-5' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <Container>
                <Row className='mt-4 mb-4'>
                    {indicators.map((ind, i) => (
                        <Col xs={3}>
                            <Card className={`statusCard ${ind.color} administration`}>
                                <Card.Body>
                                    <Card.Title>{ind.name}</Card.Title>
                                    <Card.Text>
                                        <p className='m-0' >
                                            <span style={{ fontSize: 28, fontStyle: 'bold' }}>{ind.value}</span>
                                            <span className='ms-1' style={{ fontSize: 13, color: 'gray' }}>{ind.text}</span>
                                        </p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>


                <Row className='mb-5'>
                    <Col>
                        <Card className='cards' style={{ minHeight: 583 }}>
                            <Card.Body>
                                <Card.Title>
                                    <Row>
                                        <Col xs={3} className='mt-2'><span className='mt-5'>Tareas</span></Col>
                                        <Col style={{ textAlign: 'right' }}><Button variant="success" onClick={handleShowNewNotificationModal}><FontAwesomeIcon icon={faBell} style={{ marginRight: 8 }} />Nueva notificación</Button><Button variant="success" className='ms-2' onClick={handleShowNewTaskModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva tarea</Button></Col>
                                    </Row>
                                </Card.Title>





                                <Row className=''>

                                    <Col>
                                        <div className='mt-2'>
                                            {tasks.length > 0 ?
                                                <div>
                                                    {tasks.map((t, i) => (
                                                        <div className='file element mb-2' >
                                                            <table>
                                                                <tr>
                                                                    <td className='w-100'>
                                                                        <p className='m-0' style={{ fontSize: 12 }}>{t.proceedingName}</p>
                                                                        <p className='m-0'>{t.title}</p>
                                                                        <p className='m-0' style={{ fontSize: 12 }}>{t.description}</p>
                                                                        <p className='mt-1 mb-0'>{timeDueWithBadge(t.dueDate)}</p>

                                                                    </td>
                                                                    <td className='w-100'>
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item onClick={() => goToProceeding(t.proceedingUrl)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 7, marginLeft: 2 }} />Ir al expediente</Dropdown.Item>
                                                                                <Dropdown.Item onClick={() => setTaskAsCompleted(t.id, t.proceedingId)}><FontAwesomeIcon icon={faCheck} style={{ marginRight: 10, marginLeft: 2 }} />Marcar como completado</Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    ))}
                                                </div>
                                                :
                                                <Container style={{marginTop: 100}}>
                                                    <Row>
                                                        <Col style={{ textAlign: 'center', fontSize: 60 }}>
                                                            <FontAwesomeIcon icon={faTasks} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col style={{ textAlign: 'center' }}>
                                                            No hay tareas
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            }


                                        </div>
                                    </Col>

                                </Row>




                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='mb-5'>
                        <Card className='cards' style={{ minHeight: 583 }}>
                            <Card.Body>
                                <Card.Title>Expedientes activos</Card.Title>
                                <Card.Text className='ps-2 pe-2 pt-2'>


                                    {tableRows.map((r, i) => (
                                        <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8, minHeight: 65 }}>
                                            <Col xs={1} className='align-center-vertically-v2' >
                                                <div style={{ textAlign: 'center' }}>
                                                    {r.type}
                                                </div>
                                            </Col>

                                            <Col xs={7} className='align-center-vertically-v2'>
                                                <div>
                                                    {r.name}
                                                </div>
                                            </Col>

                                            <Col xs={2} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    {r.inactividad}
                                                </div>
                                            </Col>

                                            <Col xs={2} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'right' }}>
                                                    {r.action}
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}
                                </Card.Text>
                            </Card.Body>
                        </Card>


                    </Col>
                </Row>

            </Container>


            <Modal show={showNewTaskModal} onHide={handleCloseNewTaskModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva tarea</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group className=''>
                        <Form.Text>Expediente</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskProceeding(event)}>
                            <option value="0">Seleccionar...</option>
                            {proceedings.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.Status.name == 'Judicial' ? `J - ${p.CourtFile.name}` : `GDD - ${p.Debt.name}`}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Encargado</Form.Text>
                        <Row>
                            <Col>
                                <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskUser(event)}>
                                    <option value="0">Seleccionar...</option>
                                    {lawers.map((p, i) => (
                                        <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col xs={3} style={{ textAlign: 'right' }}>
                                <ToggleButton
                                    className=""
                                    id="newTaskNotification"
                                    type="checkbox"
                                    variant="outline-success"
                                    checked={newTaskNotificationChecked}
                                    value="1"
                                    onChange={(e) => handleNewTaskNotification(e.currentTarget.checked)}
                                >
                                    Notificar
                                </ToggleButton>
                            </Col>
                        </Row>

                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Título</Form.Text>
                        <Form.Control type="text" id="newTaskTitle" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control as="textarea" rows={4} id="newTaskDescription" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' minDate={new Date()} dateFormat="dd/MM/yyyy" selected={newTaskDate} onChange={(d) => handleNewTaskDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Recordar</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskReminder(event)}>
                            <option value="0">No recordar</option>
                            {reminderOptions.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    {error}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewTaskModal}>
                        Cerrar
                    </Button>

                    <Button variant="success" onClick={createTask}>Crear tarea</Button>


                </Modal.Footer>
            </Modal>




            <Modal show={showNewNotificationModal} onHide={handleCloseNewNotificationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva notificación</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group className=''>
                        <Form.Text>Expediente</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskProceeding(event)}>
                            <option value="0">Seleccionar...</option>
                            {proceedings.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.Status.name == 'Judicial' ? `J - ${p.CourtFile.name}` : `GDD - ${p.Debt.name}`}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Encargado</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskUser(event)}>
                            <option value="0">Seleccionar...</option>
                            {lawers.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Título</Form.Text>
                        <Form.Control type="text" id="newNotificationTitle" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control as="textarea" rows={4} id="newNotificationDescription" placeholder="" />
                    </Form.Group>
                    {error}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewNotificationModal}>
                        Cerrar
                    </Button>

                    <Button variant="success" onClick={createNotification}>Enviar notificación</Button>


                </Modal.Footer>
            </Modal>


        </div>
    )
}