import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, DropdownButton, Badge, Col, Modal, ButtonGroup, ToggleButton, Form, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faGavel, faEye, faCoins, faCashRegister, faDollarSign, faEllipsisV, faFilePdf, faDownload, faTrash, faCheck, faCross, faTimes } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { del, get, post, put } from '../../../controllers/endpoints.controller';
import { getUserToken, logout, getUserBusinessId } from '../../../controllers/user.controller';
import { pn } from '../../../tools/chains';
import { convert2Base64, processDate, timeDue, getFileIcon, timeDueWithBadge } from '../../../tools/tools';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import PDFViewer from 'mgr-pdf-viewer-react';
import { colors } from '../../../tools/constants';

export default function CourtFiles() {

    const PAGE_TITLE = "Cuenta corriente";
    const PAGE_ICON = faCoins;
    const [businessName, setBusinessName] = React.useState("");
    const [currentAccount, setCurrentAccount] = React.useState([]);
    const [currentAccountPPO, setCurrentAccountPPO] = React.useState([]);
    const [allCurrentAccount, setAllCurrentAccount] = React.useState([]);
    const [currentAccountIndicators, setCurrentAccountIndicators] = React.useState([]);
    const [error, setError] = React.useState(<div></div>);

    const [showViewFileModal, setShowViewFileModal] = React.useState(false);
    const [actualViewFile, setActualViewFile] = React.useState("");
    const handleCloseViewFileModal = () => { setShowViewFileModal(false); };
    const handleShowViewFileModal = () => {
        setShowViewFileModal(true);
    };

    const [selectedCustomerBalanceId, setSelectedCustomerBalanceId] = React.useState("0");
    const [showDeleteOperationModal, setShowDeleteOperationModal] = React.useState(false);
    const [selectedOperationId, setSelectedOperationId] = React.useState("0");
    const [selectedOperationDescription, setSelectedOperationDescription] = React.useState("");
    const handleCloseDeleteOperationModal = () => { setShowDeleteOperationModal(false); };
    const handleShowDeleteOperationModal = (id, description) => {
        setSelectedOperationId(id)
        setSelectedOperationDescription(description)
        setShowDeleteOperationModal(true);
    }

    const [showNewPaymentToCustomerModal, setShowNewPaymentToCustomerModal] = React.useState(false);
    const [newPaymentToCustomerCustomer, setNewPaymentToCustomerCustomer] = React.useState("");
    const [newPaymentToCustomerName, setNewPaymentToCustomerName] = React.useState("");
    const [newPaymentToCustomerBalance, setNewPaymentToCustomerBalance] = React.useState("");
    const handleCloseNewPaymentToCustomerModal = () => { setShowNewPaymentToCustomerModal(false); };
    const handleShowNewPaymentToCustomerModal = (cname, cid, balance) => {
        setNewPaymentToCustomerBalance(balance)
        setNewPaymentToCustomerCustomer(cid)
        setNewPaymentToCustomerName(cname)
        setShowNewPaymentToCustomerModal(true);
    }
    const [showDeleteCustomerBalanceModal, setShowDeleteCustomerBalanceModal] = React.useState(false);
    const handleCloseDeleteCustomerBalanceModal = () => { setShowDeleteCustomerBalanceModal(false); };
    const handleShowDeleteCustomerBalanceModal = (id) => {
        setSelectedCustomerBalanceId(id)
        setShowDeleteCustomerBalanceModal(true);
    }

    const [showNewPaymentModal, setShowNewPaymentModal] = React.useState(false);
    const handleCloseNewPaymentModal = () => { setShowNewPaymentModal(false); };
    const handleShowNewPaymentModal = () => {
        setError();
        setShowNewPaymentModal(true);
    };
    const [newPaymentDate, setNewPaymentDate] = React.useState(new Date());

    const [showPayOffModal, setShowPayOffModal] = React.useState(false);
    const handleClosePayOffModal = () => { setShowPayOffModal(false); };
    const handleShowPayOffModal = () => {
        setError();
        setShowPayOffModal(true);
    };


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });
        registerLocale("es", { ...es, options: { ...es.options, weekStartsOn: 0 } });
        document.title = `EAC - ${PAGE_TITLE}`;

        getData();
    }, []);

    const getData = async () => {
        getCurrentAccount();
        getCustomerBalanceIndicators();
        getBusiness();
        getCurrentAccountPPO();
    }

    const handleNewPaymentDate = (event) => {
        setNewPaymentDate(event);
    };

    const handlePaymentData = (event, type) => {
        const data = event.target.value;
        let a;
        let f;

        if (type === "amount") {
            if (document.getElementById('newPaymentFee').value.length > 0 && document.getElementById('newPaymentAmount').value.length > 0) {
                a = parseFloat(data);
                f = parseFloat(document.getElementById('newPaymentFee').value)
                document.getElementById('newPaymentFeeAmount').value = (a * f / 100)
            }
        } else if (type === "fee") {
            if (document.getElementById('newPaymentFee').value.length > 0 && document.getElementById('newPaymentAmount').value.length > 0) {
                f = parseFloat(data);
                a = parseFloat(document.getElementById('newPaymentAmount').value)
                document.getElementById('newPaymentFeeAmount').value = (a * f / 100)
            }
        }

    }

    const getBusinessId = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const getBusiness = async function () {
        const req = await get(`/customers/business/byId/${getUserBusinessId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setBusinessName(res.name)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCurrentAccountPPO = async function () {
        const req = await get(`/administration/balanceppo/${getUserBusinessId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processCustomerBalanceById(res, true)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCurrentAccount = async function () {
        const req = await get(`/administration/balance/${getUserBusinessId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processCustomerBalanceById(res, false)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }

    }

    const payOff = async () => {
        let body = [];

        let check;

        for(let p of currentAccountPPO){
            check = document.getElementById(`checkPayOff-${p.id}`).checked;

            if(check){
                body.push({id: p.id, executed: check})
            }
        }
        console.log(body)

        const req = await post(`/administration/payoff`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            getData();
            handleClosePayOffModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }

    }

    const showError = (title) => {
        setError(
            <Alert className='mt-3 mb-0' key='danger' variant='danger'>
                {title}
            </Alert>
        )
    }

    const deleteOperation = async () => {
        const req = await del(`/administration/currentAccount`, getUserToken(), { id: selectedOperationId });
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const payToCustomer = async () => {
        const data = {
            businessId: newPaymentToCustomerCustomer,
            amount: document.getElementById('newPaymentToCustomerAmount').value
        }

        const req = await post(`/administration/balance`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processCustomerBalanceById = (data, ppo) => {
        let result = [];
        for (let e of data) {
            e.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleShowDeleteCustomerBalanceModal(e.id, e.description)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
            if (e.Proceeding) {
                e.name = e.Proceeding.Debt.name;
            } else {
                e.name = '';
            }

            if (!e.description) {
                e.description = "Liquidación";
            }

            if (e.executed) {
                e.exe = <Badge className='' bg='success'>Si</Badge>;
                e.exeDateFormatted = moment(new Date(e.exeDate)).format("DD/MM/YYYY");
            } else {
                e.exe = <Badge className='' bg='danger'>No</Badge>;
                e.exeDateFormatted = "";
            }

            result.push(e)
        }

        if(ppo){
            setCurrentAccountPPO(result)
        }else{
            setCurrentAccount(result)
        }
        
    }

    const deleteCustomerBalance = async () => {
        const req = await del(`/administration/balance`, getUserToken(), { id: selectedCustomerBalanceId });
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCustomerBalanceIndicators = async () => {
        const req = await get(`/administration/balance/${getUserBusinessId()}/indicators`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setCurrentAccountIndicators(res)
        } else if (req.status === 503) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createNewPayment = async function () {
        if (checkNewPayment()) {
            const a = parseFloat(document.getElementById('newPaymentAmount').value);
            const f = parseFloat(document.getElementById('newPaymentFeeAmount').value);

            const data = {
                proceedingId: null,
                date: newPaymentDate,
                amount: a,
                customer: null,
                study: null,
                executed: false,
                fileId: null,
                customerId: getBusinessId(),
                fee: f,
                income: true,
                description: null
            };

            const req = await post(`/administration/payments`, getUserToken(), data);
            const res = await req.json();

            if (req.status === 201) {
                getData();
                handleCloseNewPaymentModal();
            } else {
                alert(res.message);
            }
        }
    }


    const checkNewPayment = () => {
        let result = true;
        const a = parseFloat(document.getElementById('newPaymentAmount').value);
        const f = parseFloat(document.getElementById('newPaymentFee').value);

        if (a.length == 0) {
            result = false;
            showError('Escriba el monto del pago')
        } else if (f.length == 0) {
            result = false;
            showError('Escriba el porcentaje de honorarios')
        } else if (newPaymentDate === null) {
            result = false;
            showError('No hay una fecha especificada')
        }

        return result;
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>


                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                            <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: '#A0A0A0' }}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    Cuenta corriente
                                </td>
                                <td className='content-options rows right'>
                                    
                                </td>
                            </tr>
                        </table>

                        <Container>
                            <Row className='mt-4 mb-4'>

                                {currentAccountIndicators.map((ind, i) => (
                                    <Col key={i} xs={3}>
                                        <Card className={`statusCard ${ind.color} administration`}>
                                            <Card.Body>
                                                <Card.Title>{ind.name}</Card.Title>
                                                <Card.Text>
                                                    <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>{ind.value}</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}


                            </Row>
                        </Container>

                        {currentAccount.length > 0 ?
                            <Container className='pt-3 mb-3'>
                                <div className='d-none d-sm-block'>
                                    <Row className=' p-2 mb-2' style={{ background: `${colors().brand_blue}`, color: 'white', borderRadius: 8 }}>
                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div>
                                                Fecha
                                            </div>
                                        </Col>

                                        <Col sm={3} className='align-center-vertically-v2' >
                                            <div>
                                                Carátula
                                            </div>
                                        </Col>

                                        <Col sm={3} className='align-center-vertically-v2' >
                                            <div>
                                                Descripción
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Importe
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                Honorarios
                                            </div>
                                        </Col>
                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                Liq.
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {currentAccount.map((r, i) => (
                                    <div>
                                        <div className='d-none d-sm-block'>
                                            <Row className='p-2 mb-2' style={{ background: r.income ? '#d8eddd' : '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={1} className='align-center-vertically'>
                                                    <Moment format="DD/MM/YY">{r.date}</Moment>

                                                </Col>
                                                <Col xs={3} className='align-center-vertically-v2'>
                                                    <div>

                                                        <p className='m-0'>{r.name}</p>
                                                    </div>

                                                </Col>
                                                <Col xs={3} className='align-center-vertically-v2'>
                                                    <div>

                                                        <p className='m-0'>{r.description}</p>
                                                    </div>

                                                </Col>
                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        {r.income == false ? `$${pn(r.customer)}` : ""}
                                                        {r.income == true ? `$${pn(r.amount * -1)}` : ""}
                                                    </div>
                                                </Col>
                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        {r.income == true ? `$${pn(r.fee)}` : ""}
                                                    </div>
                                                </Col>

                                                <Col xs={1} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <p className='m-0'>{r.exe}</p>
                                                        <p className='mt-1 mb-0' style={{ fontSize: 12 }}>{r.exeDateFormatted}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>


                                    </div>

                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCoins}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron movimientos</p>
                                </Row>
                            </Container>

                        }

                    </Card>
                </Row>

            </Container>



            <Modal size='lg' show={showViewFileModal} onHide={handleCloseViewFileModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PDFViewer document={{
                        base64: actualViewFile
                    }} />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseViewFileModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteOperationModal} onHide={handleCloseDeleteOperationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea eliminar la operación "{selectedOperationDescription}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteOperationModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={deleteOperation}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewPaymentToCustomerModal} onHide={handleCloseNewPaymentToCustomerModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Transferir pago</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Cliente</Form.Text>
                        <Form.Control type="text" id="newPaymentToCustomerCustomer" placeholder="" readOnly value={newPaymentToCustomerName} />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Balance</Form.Text>
                        <Form.Control type="text" id="newPaymentToCustomerBalance" placeholder="" readOnly value={newPaymentToCustomerBalance} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Monto a transferir</Form.Text>
                        <Form.Control type="text" id="newPaymentToCustomerAmount" placeholder="" />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewPaymentToCustomerModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={payToCustomer}>Realizar pago</Button>


                </Modal.Footer>
            </Modal>



            <Modal show={showDeleteCustomerBalanceModal} onHide={handleCloseDeleteCustomerBalanceModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea eliminar la operación? Tené en cuenta que no se eliminará el honorario de la cuenta corriente
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteCustomerBalanceModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={deleteCustomerBalance}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewPaymentModal} onHide={handleCloseNewPaymentModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo pago a cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group className=''>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newPaymentDate} onChange={(d) => handleNewPaymentDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Monto</Form.Text>
                        <Form.Control type="text" id="newPaymentAmount" placeholder="" onChange={(event) => handlePaymentData(event, 'amount')} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Honorarios (%)</Form.Text>
                        <Form.Control type="text" id="newPaymentFee" placeholder="" defaultValue='15' onChange={(event) => handlePaymentData(event, 'fee')} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Control type="text" id="newPaymentFeeAmount" defaultValue='0' placeholder="" readOnly />
                    </Form.Group>

                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewPaymentModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createNewPayment}>Confirmar</Button>


                </Modal.Footer>
            </Modal>



            <Modal show={showPayOffModal} size='lg' onHide={handleClosePayOffModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Liquidar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentAccountPPO.length > 0 ?
                        <Container className='mb-3'>
                            <div className='d-none d-sm-block'>
                                <Row className=' p-2 mb-2' style={{ background: `${colors().brand_blue}`, color: 'white', borderRadius: 8 }}>
                                <Col sm={1} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>

                                        </div>
                                    </Col>
                                    <Col sm={2} className='align-center-vertically-v2'>
                                        <div>
                                            Fecha
                                        </div>
                                    </Col>

                                    <Col sm={3} className='align-center-vertically-v2' >
                                        <div>
                                            Carátula
                                        </div>
                                    </Col>

                                    <Col sm={4} className='align-center-vertically-v2' >
                                        <div>
                                            Descripción
                                        </div>
                                    </Col>

                                    <Col sm={2} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            Importe
                                        </div>
                                    </Col>

                                   
                                </Row>
                            </div>

                            {currentAccountPPO.map((r, i) => (
                                <div>
                                    <div className='d-none d-sm-block'>
                                        <Row className='p-2 mb-2' style={{ background: r.income ? '#d8eddd' : '#F0F0F0', borderRadius: 8 }}>
                                        <Col xs={1} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'right' }}>
                                                    <Form.Check
                                                        type='checkbox'
                                                        id={`checkPayOff-${r.id}`}
                                                        label=''
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={2} className='align-center-vertically'>
                                                <Moment format="DD/MM/YY">{r.date}</Moment>

                                            </Col>
                                            <Col xs={3} className='align-center-vertically-v2'>
                                                <div>

                                                    <p className='m-0'>{r.name}</p>
                                                </div>

                                            </Col>
                                            <Col xs={4} className='align-center-vertically-v2'>
                                                <div>

                                                    <p className='m-0'>{r.description}</p>
                                                </div>

                                            </Col>
                                            <Col xs={2} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    {r.income == false ? `$${pn(r.customer)}` : ""}
                                                    {r.income == true ? `$${pn(r.amount * -1)}` : ""}
                                                </div>
                                            </Col>

                                            
                                        </Row>
                                    </div>


                                </div>

                            ))}
                        </Container>
                        :
                        <Container className='mt-5 mb-5'>

                            <Row style={{ height: 300, textAlign: "center" }}>
                                <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCoins}></FontAwesomeIcon></p>
                                <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron movimientos</p>
                            </Row>
                        </Container>

                    }


                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePayOffModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={payOff}>Confirmar</Button>


                </Modal.Footer>
            </Modal>
        </div>
    )
}