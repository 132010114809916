import React, {useEffect} from 'react'
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Col, Badge, Dropdown, Button, Form, Modal} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCalendarAlt} from '@fortawesome/free-regular-svg-icons'
import {faCheck, faFilePdf, faEllipsisV, faCommentAlt, faArrowLeft, faPlusCircle, faGavel, faTrash, faSearch, faTasks, faCogs, faEye, faLink} from '@fortawesome/free-solid-svg-icons'
import '@fortawesome/fontawesome-free/css/all.css';
import NavBar from "../navbar/Navbar";
import WebFont from 'webfontloader';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import moment from 'moment'
import 'moment/locale/es-mx';
import {get, put, post, del} from '../../../controllers/endpoints.controller';
import {getUserToken, logout} from '../../../controllers/user.controller';
import { processDate, processDateCalendar, timeDue } from '../../../tools/tools';
import { params } from '../../../tools/constants';


export default function Settings(){

    moment.locale('es-mx');
    const PAGE_TITLE = "Configuración";
    const PAGE_ICON = faCogs;
    const [usersCol, setUsersCol] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [actionCategories, setActionCategories] = React.useState([]);
    const [administratorCategories, setAdministratorCategories] = React.useState([]);

    const [color, setColor] = React.useState("");  
    const [showNewActionCategoryModal, setShowNewActionCategoryModal] = React.useState(false);
    const handleCloseNewActionCategoryModal = () => {setShowNewActionCategoryModal(false);};
    const handleShowNewActionCategoryModal = () => {
        setShowNewActionCategoryModal(true);
    };

    const [showNewAdministrationCategoryModal, setShowNewAdministrationCategoryModal] = React.useState(false);
    const handleCloseNewAdministrationCategoryModal = () => {setShowNewAdministrationCategoryModal(false);};
    const handleShowNewAdministrationCategoryModal = () => {
        setShowNewAdministrationCategoryModal(true);
    };

    const [showDeleteActionCategoryModal, setShowDeleteActionCategoryModal] = React.useState(false);
    const handleCloseDeleteActionCategoryModal = () => {setShowDeleteActionCategoryModal(false);};
    const handleShowDeleteActionCategoryModal = (id, name) => {
        setCategoryId(id);
        setCategoryName(name);
        setShowDeleteActionCategoryModal(true);
    };

    const [showDeleteAdministrationCategoryModal, setShowDeleteAdministrationCategoryModal] = React.useState(false);
    const handleCloseDeleteAdministrationCategoryModal = () => {setShowDeleteAdministrationCategoryModal(false);};
    const handleShowDeleteAdministrationCategoryModal = (id, name) => {
        setCategoryId(id);
        setCategoryName(name);
        setShowDeleteAdministrationCategoryModal(true);
    };

    const [categoryId, setCategoryId] = React.useState(null);
    const [categoryName, setCategoryName] = React.useState(null);
    
    const [integrations, setIntegrations] = React.useState([]);  
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `EAC - ${PAGE_TITLE}`;
        getUsers();
        getActionCategories();
        getAdministrationCategories();
    }, []);

    const handleColor = (event) => {
        setColor(event.target.value);
    };

    
    const getUsers = async function(){
        const req = await get('/users', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setUsersCol(['', 'Nombre y apellido', 'Correo electrónico', 'Rol', 'Estado', 'Detalles'])
            processUsers(res)
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    const processUsers = (data) => {
        let result = [];

        for(let u of data){
            u.status = u.enabled ? <Badge bg='success' className='content-table status'>Habilitado</Badge> : <Badge bg='danger' className='content-table status'>Bloqueado</Badge>
            if(u.token == getUserToken()){
                u.actions = <Link to={`/configuracion/usuarios/detalles/`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
            }else{
                u.actions = <Link to={`/sistema/usuarios/detalles/?id=${u.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
            }
            
            result.push(u);
        }

        setUsers(result)
    }

    const getActionCategories = async function(){
        const req = await get(`/proceedings/actions/categories`, getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setActionCategories(res)
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    const createActionCategory = async function(){
        const data = {
            name: document.getElementById('textActionCategoryName').value,
            color: color
        }
        const req = await post(`/proceedings/actions/categories`, getUserToken(), data);
        const res = await req.json();

        if(req.status === 201){
            getActionCategories();
            handleCloseNewActionCategoryModal()
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    const getAdministrationCategories = async function(){
        const req = await get(`/administration/categories`, getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setAdministratorCategories(res)
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    const createAdministrationCategory = async function(){
        const data = {
            name: document.getElementById('textAdministrationCategoryName').value,
            color: color
        }
        const req = await post(`/administration/categories`, getUserToken(), data);
        const res = await req.json();

        if(req.status === 201){
            getAdministrationCategories();
            handleCloseNewAdministrationCategoryModal()
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    const deleteActionCategory = async function(){
        const data = {
            id: categoryId
        }
        const req = await del(`/proceedings/actions/categories`, getUserToken(), data);
        const res = await req.json();

        if(req.status === 201){
            getActionCategories();
            handleCloseDeleteActionCategoryModal()
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    const deleteAdministrationCategory = async function(){
        const data = {
            id: categoryId
        }
        const req = await del(`/administration/categories`, getUserToken(), data);
        const res = await req.json();

        if(req.status === 201){
            getAdministrationCategories();
            handleCloseDeleteAdministrationCategoryModal()
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                <span style={{color: '#A0A0A0'}}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <span style={{fontSize: 18}}>Usuarios</span>
                                </td>
                                <td className='content-options rows right'>
                                    <Link to={'/sistema/usuarios/nuevo'}><Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 8}} />Nuevo usuario</Button></Link>
                                </td>
                            </tr>
                        </table>

                        <table striped hover className='mb-3 w-100'>
                            <thead>
                                <tr>
                                    {usersCol.map((c, i) => (
                                        <th key={i} className='content-table head'>{c}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((r, i) => (
                                    <tr key={i} >
                                        <td className='content-table rows middle align-middle' style={{width: 70}}><img className='avatar-10' style={{objectFit: 'cover'}} src={`${params().dataUri}${r.avatar}`}/></td>
                                        <td className='content-table rows  align-middle'>{r.name} {r.surname}</td>
                                        <td className='content-table rows middle align-middle'>{r.email}</td>
                                        <td className='content-table rows middle align-middle'>{r.Role.name}</td>
                                        <td className='content-table rows middle align-middle'>{r.status}</td>
                                        <td className='content-table rows middle align-middle'>{r.actions}</td>
                                    </tr>
                                ))}
                                
                            </tbody>
                        </table>     
                    </Card>
                </Row>      

                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <span style={{fontSize: 18}}>Categorías de acciones</span>
                                </td>
                                <td className='content-options rows right'>
                                    <Button variant="success" onClick={handleShowNewActionCategoryModal}><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 8}} />Nueva categoría</Button>
                                </td>
                            </tr>
                        </table>

                        <p className='mt-3 ps-4 pe-4'>
                            {actionCategories.map((r, i) => (
                                <Row className='p-2 mb-3' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                <Col xs={11} className='align-center-vertically middle'>
                                    <div>
                                        <p className='m-0'><Badge bg='' className='content-table status me-2' style={{background: `${r.color}`}}><span className='m-1'> </span></Badge> {r.name}</p>
                                    </div>
                                </Col>
                                

                                <Col xs={1} className='align-center-vertically' style={{ textAlign: 'right' }}>
                                    <div>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>

                                                <Dropdown.Item onClick={() => handleShowDeleteActionCategoryModal(r.id, r.name)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                            ))}
                        </p>
                        
                    </Card>
                </Row>    

                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <span style={{fontSize: 18}}>Categorías de pagos</span>
                                </td>
                                <td className='content-options rows right'>
                                    <Button variant="success" onClick={handleShowNewAdministrationCategoryModal}><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 8}} />Nueva categoría</Button>
                                </td>
                            </tr>
                        </table>

                        <p className='mt-3 ps-4 pe-4'>

                        {administratorCategories.map((r, i) => (
                                <Row className='p-2 mb-3' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                <Col xs={11} className='align-center-vertically middle'>
                                    <div>
                                        <p className='m-0'><Badge bg='' className='content-table status me-2' style={{background: `${r.color}`}}><span className='m-1'> </span></Badge> {r.name}</p>
                                    </div>
                                </Col>
                                

                                <Col xs={1} className='align-center-vertically' style={{ textAlign: 'right' }}>
                                    <div>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>

                                            <Dropdown.Item onClick={() => handleShowDeleteAdministrationCategoryModal(r.id, r.name)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                            ))}
                        </p>
                        
                    </Card>
                </Row>   

                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options mt-4 mb-4'>
                            <tr>
                                <td className='content-options rows left'>
                                    <span style={{fontSize: 18}}>Versión del sistema: v0.14.1 (11/07/2023)</span>
                                </td>
                                <td className='content-options rows right'>
                                    
                                </td>
                            </tr>
                        </table>
                    </Card>
                </Row>   
            </Container>

            <Modal show={showNewActionCategoryModal} onHide={handleCloseNewActionCategoryModal}>
                <Modal.Header closeButton>
                <Modal.Title>Nueva categoría de acción</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='mt-2'>
                        <Form.Text>Nombre</Form.Text>
                        <Row>
                            <Col>
                                <Form.Control type="text" id="textActionCategoryName" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control 
                                    type="color"
                                    id="exampleColorInput"
                                    defaultValue="#563d7c"
                                    title="Choose your color"
                                    onChange = {(event) => handleColor(event)}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseNewActionCategoryModal}>
                    Cerrar
                </Button>

                <Button variant="danger" onClick={createActionCategory}>Confirmar</Button>
                

                </Modal.Footer>
            </Modal>

            <Modal show={showNewAdministrationCategoryModal} onHide={handleCloseNewAdministrationCategoryModal}>
                <Modal.Header closeButton>
                <Modal.Title>Nueva categoría de pagos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='mt-2'>
                        <Form.Text>Nombre</Form.Text>
                        <Row>
                            <Col>
                                <Form.Control type="text" id="textAdministrationCategoryName" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control 
                                    type="color"
                                    id="exampleColorInput"
                                    defaultValue="#563d7c"
                                    title="Choose your color"
                                    onChange = {(event) => handleColor(event)}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseNewAdministrationCategoryModal}>
                    Cerrar
                </Button>

                <Button variant="danger" onClick={createAdministrationCategory}>Confirmar</Button>
                

                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteActionCategoryModal} centered onHide={handleCloseDeleteActionCategoryModal}>
                <Modal.Header >
                    <Modal.Title>Eliminar categoría</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de eliminar la categoría "{categoryName}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteActionCategoryModal}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={deleteActionCategory}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteAdministrationCategoryModal} centered onHide={handleCloseDeleteAdministrationCategoryModal}>
                <Modal.Header >
                    <Modal.Title>Eliminar categoría</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de eliminar la categoría "{categoryName}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteAdministrationCategoryModal}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={deleteAdministrationCategory}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}