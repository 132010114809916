import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, DropdownButton, Badge, Col, Modal, ButtonGroup, ToggleButton, Form, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faGavel, faEye, faCoins, faCashRegister, faDollarSign, faEllipsisV, faFilePdf, faDownload, faTrash, faCheck, faCross, faTimes } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { del, get, post, put } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { pn } from '../../../tools/chains';
import { convert2Base64, processDate, timeDue, getFileIcon, timeDueWithBadge } from '../../../tools/tools';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import PDFViewer from 'mgr-pdf-viewer-react';
import { colors } from '../../../tools/constants';

export default function CourtFiles() {

    const PAGE_TITLE = "Flujo de caja";
    const PAGE_ICON = faCoins;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [currentAccount, setCurrentAccount] = React.useState([]);
    const [allCurrentAccount, setAllCurrentAccount] = React.useState([]);
    const [currentAccountIndicators, setCurrentAccountIndicators] = React.useState([]);
    const [administrationCategories, setAdministrationCategories] = React.useState([]);
    const [error, setError] = React.useState(<div></div>);

    const [newOperationDate, setNewOperationDate] = React.useState(new Date());
    const [newOperationFile, setNewOperationFile] = React.useState(null);
    const [newOperationCategory, setNewOperationCategory] = React.useState("0");
    const [showNewOperationModal, setShowNewOperationModal] = React.useState(false);
    const handleCloseNewOperationModal = () => { setShowNewOperationModal(false); };
    const handleShowNewOperationModal = () => {
        setError()
        setShowNewOperationModal(true);
    };
    const [newOperationIsCredit, setNewOperationIsCredit] = React.useState(false);

    const [showViewFileModal, setShowViewFileModal] = React.useState(false);
    const [actualViewFile, setActualViewFile] = React.useState("");
    const handleCloseViewFileModal = () => { setShowViewFileModal(false); };
    const handleShowViewFileModal = () => {
        setShowViewFileModal(true);
    };

    const [showDeleteOperationModal, setShowDeleteOperationModal] = React.useState(false);
    const [selectedOperationId, setSelectedOperationId] = React.useState("0");
    const [selectedOperationDescription, setSelectedOperationDescription] = React.useState("");
    const handleCloseDeleteOperationModal = () => { setShowDeleteOperationModal(false); };
    const handleShowDeleteOperationModal = (id, description) => {
        setSelectedOperationId(id)
        setSelectedOperationDescription(description)
        setShowDeleteOperationModal(true);
    }

    const [showNewPaymentToCustomerModal, setShowNewPaymentToCustomerModal] = React.useState(false);
    const [newPaymentToCustomerCustomer, setNewPaymentToCustomerCustomer] = React.useState("");
    const [newPaymentToCustomerName, setNewPaymentToCustomerName] = React.useState("");
    const [newPaymentToCustomerBalance, setNewPaymentToCustomerBalance] = React.useState("");
    const handleCloseNewPaymentToCustomerModal = () => { setShowNewPaymentToCustomerModal(false); };
    const handleShowNewPaymentToCustomerModal = (cname, cid, balance) => {
        setNewPaymentToCustomerBalance(balance)
        setNewPaymentToCustomerCustomer(cid)
        setNewPaymentToCustomerName(cname)
        setShowNewPaymentToCustomerModal(true);
    }

    const [showCustomerBalanceModal, setShowCustomerBalanceModal] = React.useState(false);
    const [customerBalanceCol, setCustomerBalanceCol] = React.useState([]);
    const [customerBalance, setCustomerBalance] = React.useState([]);
    const [customerBalanceIndicators, setCustomerBalanceIndicators] = React.useState([]);
    const [customerBalanceCustomer, setCustomerBalanceCustomer] = React.useState("");
    const [selectedCustomerBalanceId, setSelectedCustomerBalanceId] = React.useState("0");
    const handleCloseCustomerBalanceModal = () => { setShowCustomerBalanceModal(false); };
    const handleShowCustomerBalanceModal = (businessId, customer) => {
        getCustomerBalanceById(businessId);
        setCustomerBalanceCustomer(customer);
        setShowCustomerBalanceModal(true);
    }
    const [showDeleteCustomerBalanceModal, setShowDeleteCustomerBalanceModal] = React.useState(false);
    const handleCloseDeleteCustomerBalanceModal = () => { setShowDeleteCustomerBalanceModal(false); };
    const handleShowDeleteCustomerBalanceModal = (id) => {
        setSelectedCustomerBalanceId(id)
        setShowDeleteCustomerBalanceModal(true);
    }

    const [showAllCurrentAccountModal, setShowAllCurrentAccountModal] = React.useState(false);
    const handleCloseAllCurrentAccountModal = () => { setShowAllCurrentAccountModal(false); };
    const handleShowAllCurrentAccountModal = () => {
        getAllCurrentAccount()
        setShowAllCurrentAccountModal(true);
    }


    const [pendingPaymentsCol, setPendingPaymentsCol] = React.useState([]);
    const [pendingPayments, setPendingPayments] = React.useState([]);

    const handleNewOperationIsCredit = (event) => {
        setNewOperationIsCredit(event == "Egreso");
    };

    const handleNewOperationDate = (event) => {
        setNewOperationDate(event);
    };

    const handleNewOperationCategory = (event) => {
        setNewOperationCategory(event.target.value);
    };

    const handleNewOperationFile = async (event) => {
        const file = event.target.files[0]
        setNewOperationFile(file)
    }

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });
        registerLocale("es", { ...es, options: { ...es.options, weekStartsOn: 0 } });
        document.title = `EAC - ${PAGE_TITLE}`;
        getCurrentAccount();
        getCurrentAccountIndicators();
        getAdministrationCategories();
    }, []);

    const getCurrentAccountIndicators = async function () {
        const req = await get(`/administration/currentAccount/indicators`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setCurrentAccountIndicators(res)
        } else if (req.status === 503) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCurrentAccount = async function () {
        const req = await get(`/administration/currentAccount/all`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processCurrentAccount(res, false)
        } else if (req.status === 503) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getAllCurrentAccount = async function () {
        const req = await get(`/administration/currentAccount/all`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processCurrentAccount(res, true)
        } else if (req.status === 503) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const downloadFile = async (fileId, name) => {
        const req = await get(`/files/byId/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            const linkSource = res.file;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = name;
            downloadLink.click();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const viewFile = async (fileId, name) => {
        const req = await get(`/files/byIdNoHeader/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setActualViewFile(res.file)
            handleShowViewFileModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processCurrentAccount = (data, all) => {
        let result = [];


        for (let d of data) {
            d.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {d.file != null ? <div>{getFileIcon(d.file.url) == faFilePdf ? <Dropdown.Item onClick={() => viewFile(d.file.id, d.file.name)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 8, marginLeft: 2 }} />Ver archivo</Dropdown.Item> : ""}</div> : <div></div>}
                        {d.file != null ? <Dropdown.Item onClick={() => downloadFile(d.file.id, d.file.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 8, marginLeft: 2 }} />Descargar archivo</Dropdown.Item> : <div></div>}
                        <Dropdown.Item onClick={() => handleShowDeleteOperationModal(d.id, d.description)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
            if (d.file) {
                d.icon = getFileIcon(d.file.url);
            }

            if(d.AdministrationCategory){
                d.category = <Badge bg='' className='' style={{fontSize: 12, backgroundColor: `${d.AdministrationCategory.color}` }}>{d.AdministrationCategory.name}</Badge>;
            }else{
                d.category = <div></div>
            }
            
            result.push(d)
        }
        if (all) {
            setAllCurrentAccount(result)
        } else {
            setCurrentAccount(result)
        }
    }

    const createOperation = async () => {
        if (checkOperation()) {
            let debit = null;
            let credit = null;

            if (newOperationIsCredit) {
                credit = parseFloat(document.getElementById('newOperationAmount').value.replace(',', '.'));
            } else {
                debit = parseFloat(document.getElementById('newOperationAmount').value.replace(',', '.'));
            }

            let file = null;
            let fileName = null;
            let fileExtension = null;

            if (newOperationFile != null) {
                file = await convert2Base64(newOperationFile);
                const aux = newOperationFile.name.split('.')
                fileExtension = `.${aux[aux.length - 1]}`
                fileName = newOperationFile.name;
            }

            let data = {
                date: newOperationDate,
                description: document.getElementById('newOperationDescription').value,
                debit: debit,
                credit: credit,
                executed: false,
                file: file,
                fileName: fileName,
                fileExtension: fileExtension,
                categoryId: newOperationCategory != "0" ? newOperationCategory : null
            }

            const req = await post(`/administration/currentAccount`, getUserToken(), data);
            const res = await req.json();

            if (req.status === 201) {
                window.location.reload();
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const checkOperation = (data) => {
        let result = true;

        if (newOperationCategory == '0' || newOperationCategory == null) {
            result = false;
            showError('No se ha definido una categoría')
        } else if (document.getElementById("newOperationAmount").value.length <= 1) {
            result = false;
            showError('No se ha especificado un monto')
        }

        return result;
    }

    const showError = (title) => {
        setError(
            <Alert className='mt-3 mb-0' key='danger' variant='danger'>
                {title}
            </Alert>
        )
    }

    const setOperationAsExecuted = async (id, executed) => {
        const req = await put(`/administration/currentAccount/executed`, getUserToken(), { id: id, executed: executed });
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteOperation = async () => {
        const req = await del(`/administration/currentAccount`, getUserToken(), { id: selectedOperationId });
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCustomerBalance = async () => {
        const req = await get(`/administration/balance`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processCustomerBalance(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processCustomerBalance = (data) => {
        let result = [];
        let aux = [];
        const c = Object.keys(data);
        for (let d of c) {
            aux.push(data[d])
        }
        for (let e of aux) {
            /*e.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                       
                        <Link to={`/administracion/cliente/?id=${e.businessId}`}><Dropdown.Item><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver detalle</Dropdown.Item></Link>
                    </Dropdown.Menu>
                </Dropdown>
            )*/
            e.action = <Link to={`/administracion/cliente/?id=${e.businessId}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
            result.push(e)
        }
        console.log(result)
        setPendingPayments(result)
    }

    const payToCustomer = async () => {
        const data = {
            businessId: newPaymentToCustomerCustomer,
            amount: document.getElementById('newPaymentToCustomerAmount').value
        }

        const req = await post(`/administration/balance`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCustomerBalanceById = async (businessId) => {
        const req = await get(`/administration/balance/${businessId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processCustomerBalanceById(res)
            getCustomerBalanceIndicators(businessId)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processCustomerBalanceById = (data) => {
        let result = [];
        for (let e of data) {
            e.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleShowDeleteCustomerBalanceModal(e.id, e.description)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
            if (e.Proceeding) {
                e.name = e.Proceeding.Debt.name
            } else {
                e.name = '---'
            }

            result.push(e)
        }

        setCustomerBalance(result)
    }

    const deleteCustomerBalance = async () => {
        const req = await del(`/administration/balance`, getUserToken(), { id: selectedCustomerBalanceId });
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCustomerBalanceIndicators = async (businessId) => {
        const req = await get(`/administration/balance/${businessId}/indicators`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setCustomerBalanceIndicators(res)
        } else if (req.status === 503) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getAdministrationCategories = async function () {
        const req = await get(`/administration/categories`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setAdministrationCategories(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: '#A0A0A0' }}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    Flujo de caja
                                </td>
                                <td className='content-options rows right'>
                                    <Button variant="success" className='ms-2' onClick={handleShowNewOperationModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva operación</Button>
                                </td>
                            </tr>
                        </table>

                        <Container>
                            <Row className='mt-4 mb-4'>

                                {currentAccountIndicators.map((ind, i) => (
                                    <Col key={i} xs={3}>
                                        <Card className={`statusCard ${ind.color} administration`}>
                                            <Card.Body>
                                                <Card.Title>{ind.name}</Card.Title>
                                                <Card.Text>
                                                    <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>{ind.value}</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}


                            </Row>
                        </Container>

                        {currentAccount.length > 0 ?
                            <Container className='pt-3 mb-3'>
                                <div className='d-none d-sm-block'>
                                    <Row className=' p-2 mb-2' style={{ background: `${colors().brand_blue}`, color: 'white', borderRadius: 8 }}>
                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div>
                                                Fecha
                                            </div>
                                        </Col>

                                        <Col sm={5} className='align-center-vertically-v2' >
                                            <div>
                                                Descripción
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Ingreso
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                Egreso
                                            </div>
                                        </Col>
                                        <Col sm={2} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {currentAccount.map((r, i) => (
                                    <div>
                                        <div className='d-none d-sm-block'>
                                            <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={1} className='align-center-vertically'>
                                                    <Moment format="DD/MM">{r.date}</Moment>

                                                </Col>
                                                <Col xs={5} className='align-center-vertically-v2'>
                                                    <div>
                                                        
                                                        <p className='m-0'>{r.description}</p>
                                                    </div>

                                                </Col>
                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        {r.debit != null ? `$${pn(r.debit)}` : "---"}
                                                    </div>
                                                </Col>
                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        {r.credit != null ? `$${pn(r.credit)}` : "---"}
                                                    </div>
                                                </Col>

                                                <Col xs={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div>
                                                    <p className='m-0'>{r.category}</p>
                                                    </div>
                                                </Col>
                                                <Col xs={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {r.action}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        

                                    </div>

                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCoins}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron movimientos</p>
                                </Row>
                            </Container>

                        }
                        
                    </Card>
                </Row>

               
            </Container>

            <Modal show={showNewOperationModal} onHide={handleCloseNewOperationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva operación</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <ButtonGroup>
                            <ToggleButton
                                key={1}
                                id={1}
                                type="radio"
                                variant='outline-success'
                                name="radio"
                                value={"Ingreso"}
                                checked={newOperationIsCredit === false}
                                onChange={(e) => handleNewOperationIsCredit(e.currentTarget.value)}
                            >
                                Ingreso
                            </ToggleButton>

                            <ToggleButton
                                key={2}
                                id={2}
                                type="radio"
                                variant='outline-danger'
                                name="radio"
                                value={"Egreso"}
                                checked={newOperationIsCredit === true}
                                onChange={(e) => handleNewOperationIsCredit(e.currentTarget.value)}
                            >
                                Egreso
                            </ToggleButton>
                        </ButtonGroup>
                    </Row>

                    <Form.Group className='mt-2'>
                        <Form.Text>Monto</Form.Text>
                        <Form.Control type="text" id="newOperationAmount" placeholder="" />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newOperationDate} onChange={(d) => handleNewOperationDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control type="text" id="newOperationDescription" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Categoría</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewOperationCategory(event)}>
                            <option value="0">Seleccionar...</option>
                            {administrationCategories.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mt-2">
                        <Form.Text>Archivo</Form.Text>
                        <Form.Control accept='.pdf, .docx, .xlsx, .png, .jpg, .jpeg, .zip' onChange={(event) => handleNewOperationFile(event)} type="file" />
                    </Form.Group>

                    {error}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewOperationModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createOperation}>Crear</Button>


                </Modal.Footer>
            </Modal>

            <Modal size='lg' show={showViewFileModal} onHide={handleCloseViewFileModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PDFViewer document={{
                        base64: actualViewFile
                    }} />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseViewFileModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteOperationModal} onHide={handleCloseDeleteOperationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea eliminar la operación "{selectedOperationDescription}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteOperationModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={deleteOperation}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewPaymentToCustomerModal} onHide={handleCloseNewPaymentToCustomerModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Transferir pago</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Cliente</Form.Text>
                        <Form.Control type="text" id="newPaymentToCustomerCustomer" placeholder="" readOnly value={newPaymentToCustomerName} />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Balance</Form.Text>
                        <Form.Control type="text" id="newPaymentToCustomerBalance" placeholder="" readOnly value={newPaymentToCustomerBalance} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Monto a transferir</Form.Text>
                        <Form.Control type="text" id="newPaymentToCustomerAmount" placeholder="" />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewPaymentToCustomerModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={payToCustomer}>Realizar pago</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showCustomerBalanceModal} size='xl' onHide={handleCloseCustomerBalanceModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Balance de {customerBalanceCustomer}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className=' mb-4'>
                            {customerBalanceIndicators.map((ind, i) => (
                                <Col key={i} xs={3}>
                                    <Card className={`statusCard ${ind.color} administration`}>
                                        <Card.Body>
                                            <Card.Title>{ind.name}</Card.Title>
                                            <Card.Text>
                                                <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>{ind.value}</p>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Container>

                    <table striped hover className='mb-3 w-100'>
                        <thead>
                            <tr>
                                {customerBalanceCol.map((c, i) => (
                                    <th key={i} className='content-table head'>{c}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {customerBalance.map((r, i) => (
                                <tr key={i} >
                                    <td className='content-table rows middle align-middle' style={{ width: 150 }}>{<Moment format="DD/MM/YYYY">{r.date}</Moment>}</td>
                                    <td className='content-table rows middle align-middle'>{r.name}</td>
                                    <td className='content-table rows middle align-middle' style={{ width: 150 }}>{r.debit != null ? `$${pn(r.debit)}` : "---"}</td>
                                    <td className='content-table rows middle align-middle' style={{ width: 150 }}>{r.credit != null ? `$${pn(r.credit)}` : "---"}</td>
                                    <td className='content-table rows middle align-middle' style={{ width: 100 }}>{r.action}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCustomerBalanceModal}>
                        Cerrar
                    </Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showAllCurrentAccountModal} size='xl' onHide={handleCloseAllCurrentAccountModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Cuenta corriente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table striped hover className='mb-3 w-100'>
                        <thead>
                            <tr>
                                {tableColumns.map((c, i) => (
                                    <th key={i} className='content-table head'>{c}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {allCurrentAccount.map((r, i) => (
                                <tr key={i} >
                                    <td className='content-table rows middle align-middle' style={{ width: 150 }}>{<Moment format="DD/MM/YYYY">{r.date}</Moment>}</td>
                                    <td className='content-table rows align-middle'>{r.description}</td>
                                    <td className='content-table rows middle align-middle' style={{ width: 150 }}>{r.debit != null ? `$${pn(r.debit)}` : "---"}</td>
                                    <td className='content-table rows middle align-middle' style={{ width: 150 }}>{r.credit != null ? `$${pn(r.credit)}` : "---"}</td>
                                    <td className='content-table rows middle align-middle' style={{ width: 150 }}>{r.category}</td>
                                    <td className='content-table rows middle align-middle' style={{ width: 150 }}>{r.executed ? <Badge className='content-table status' bg='success'>Ejecutado</Badge> : <Badge className='content-table status' bg='danger'>Sin ejecutar</Badge>}</td>
                                    <td className='content-table rows middle align-middle' style={{ width: 40 }}>{r.icon != null ? <FontAwesomeIcon style={{ fontSize: 30 }} icon={r.icon}></FontAwesomeIcon> : ""}</td>
                                    <td className='content-table rows middle align-middle' style={{ width: 100 }}>{r.action}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAllCurrentAccountModal}>
                        Cerrar
                    </Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteCustomerBalanceModal} onHide={handleCloseDeleteCustomerBalanceModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea eliminar la operación? Tené en cuenta que no se eliminará el honorario de la cuenta corriente
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteCustomerBalanceModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={deleteCustomerBalance}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}