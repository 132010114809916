import {params} from '../tools/constants';

export const saveUserData = function (user){
    localStorage.setItem('userToken', user.token);
    localStorage.setItem('userName', user.name);
    localStorage.setItem('userSurname', user.surname);
    localStorage.setItem('userAvatar', user.avatar);
    localStorage.setItem('userRole', user.Role.name);
    localStorage.setItem('userId', user.id);
    if(user.Business){
        localStorage.setItem('userBusiness', user.Business.name);
        localStorage.setItem('userBusinessId', user.Business.id);
    }else{
        localStorage.setItem('userBusiness', "N/A");
        localStorage.setItem('userBusinessId', "N/A");
    }
    
    if(localStorage.getItem('userSortExpedientesCF') == null){
        localStorage.setItem('userSortExpedientesCF', "Ordenar alfabéticamente (A -> Z)");
    }
    if(localStorage.getItem('userSortExpedientesDT') == null){
        localStorage.setItem('userSortExpedientesDT', "Ordenar alfabéticamente (A -> Z)");
    }

    if(localStorage.getItem('userLawerFilterExpedientesCF') == null){
        localStorage.setItem('userLawerFilterExpedientesCF', 0);
    }
    if(localStorage.getItem('userLawerFilterExpedientesDT') == null){
        localStorage.setItem('userLawerFilterExpedientesDT', 0);
    }

    localStorage.setItem('searchChainCF', "");
    localStorage.setItem('searchChainDT', "");
}

export const validateUser = function (){
    const token = localStorage.getItem("userToken");
    if(token !== undefined && token !== null){
        if(token.length > 10){
            //Usuario está logueado
            if(window.location.href !== params.homePage){
                //Estoy en el inicio
                if(getUserRole() == "Cliente"){
                    window.location.href = "/expedientes";
                }else{
                    window.location.href = "/inicio";
                }
                
            }
            
        }else{
            window.location.href = "/login";
        }
    }else{
        window.location.href = "/login";
    }
}

export const getUserToken = function (){
    return localStorage.getItem('userToken');
}

export const getUserRole = function (){
    return localStorage.getItem('userRole');
}

export const getUserBusiness = function (){
    return localStorage.getItem('userBusiness');
}

export const getUserBusinessId = function (){
    return localStorage.getItem('userBusinessId');
}

export const getUserId = function (){
    return localStorage.getItem('userId');
}

export const logout = function (){
    localStorage.removeItem('userToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userSurname');
    localStorage.removeItem('userAvatar');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userBusiness');
    window.location.href = '/login';
}

export const forceLogout = function (user){
    localStorage.removeItem('userToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userSurname');
    localStorage.removeItem('userAvatar');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userBusiness');
    localStorage.removeItem('userSortExpedientesCF');
    localStorage.removeItem('userLawerFilterExpedientesCF');
    localStorage.removeItem('userSortExpedientesDT');
    localStorage.removeItem('userLawerFilterExpedientesDT');
    window.location.href = '/login';
}

export const saveAvatar = function (avatar){
    localStorage.setItem('userAvatar', avatar);
}