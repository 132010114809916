import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Table, Dropdown, Badge, Modal, Form, ListGroup, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowLeft, faArrowRight, faCoins, faEye, faPlusCircle, faSchool, faSearch, faUser} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import SubNavBarC from "../navbar/SubNavbarCustomers";
import SubNavBarD from "../navbar/SubNavbarDefendants";
import {get, post} from '../../../controllers/endpoints.controller';
import {getUserToken, logout} from '../../../controllers/user.controller';


export default function NewUser(props){

    const PAGE_TITLE = "Nuevo usuario";
    const PAGE_ICON = faUser;

    const [roles, setRoles] = React.useState([]);
    const [rolesById, setRolesById] = React.useState({});
    const [selectedRole, setSelectedRole] = React.useState(0);
    const [selectedIsLawer, setSelectedIsLawer] = React.useState(false);

    const [displayCustomer, setDisplayCustomer] = React.useState('d-none');
    const [displayLawer, setDisplayLawer] = React.useState('');

    const [showSearchCustomerModal, setShowSearchCustomerModal] = React.useState(false);
    const [customers, setCustomers] = React.useState([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState({});

    const handleRole = (event) => {
        setSelectedRole(event.target.value);
        if(rolesById[event.target.value] == "Cliente"){
            setDisplayCustomer('');
            setDisplayLawer('d-none');
        } else if(rolesById[event.target.value] == "Administrador" || rolesById[event.target.value] == "Empleado"){
            setDisplayCustomer('d-none');
            setDisplayLawer('');
        }
        console.log(rolesById)
    };

    const handleIsLawer = (event) => {
        setSelectedIsLawer(event.target.value);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `EAC - ${PAGE_TITLE}`;

        getRoles()
    }, []);

    const handleCloseSearchCustomerModal = () => {
        setShowSearchCustomerModal(false);
    };

    const handleOpenSearchCustomerModal = () => {
        setShowSearchCustomerModal(true);
    };

    const handleCustomerSelected = (index, isBusiness) => {
        for(let c of customers){
            if(c.id == index && c.isBusiness == isBusiness){
                setSelectedCustomer(c);
                console.log(c)
                document.getElementById('textCustomer').value = c.isBusiness == true ? (`CUIT ${c.cuit} - ${c.name}`) : `DNI ${c.dni} - ${c.surname.toUpperCase()}, ${c.name}`;
                setCustomers([]);
                handleCloseSearchCustomerModal();
            }
        }
    };

    const handleSearchCustomers = async (event) => {
        if(event.target.value.length > 2){
            const chain = {
                chain: event.target.value
            };
            const req = await post(`/customers/search`, getUserToken(), chain);
            const res = await req.json();
    
            if(req.status === 200){
                console.log(res)
                setCustomers(res)
            }else if(req.status === 403){
                logout();
            }else{
                alert(res.message);
                console.log(res)
            }
        }else{
            setCustomers([])
        }
    };

    const getRoles = async function(){
        const req = await get('/users/roles', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setRoles(res);
            let ro = {};
            for(let r of res){
                ro[r.id] = r.name
            }
            setRolesById(ro)
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }


    const createUser = async function(){
        let businessId = null;
        let personId = null;

        if(selectedCustomer){
            if(selectedCustomer.isBusiness){
                businessId = selectedCustomer.id
            }else{
                personId = selectedCustomer.id
            }
        }

        const user = {
            roleId: selectedRole,
            businessId: businessId,
            personId: personId,
            name: document.getElementById('textName').value,
            surname: document.getElementById('textSurname').value,
            email: document.getElementById('textEmail').value,
            password: null,
            token: null,
            enabled: true,
            avatar: null,
            isLawer: selectedIsLawer,
            folio: document.getElementById('textFolio').value,
            tomo: document.getElementById('textTomo').value,
            dni: document.getElementById('textDni').value
        }

        const req = await post('/users', getUserToken(), user);
        const res = await req.json();

        if(req.status === 201){
            window.location.href = '/sistema'
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

  

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                <span style={{color: '#A0A0A0'}}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <Link to={`/sistema`} className='link-primary'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 8}} />Volver a Configuración
                                    </Link>
                                    
                                    
                                </td>
                                <td className='content-options rows right'>
                                    
                                </td>
                            </tr>
                        </table>

                        <Container className='mt-4 mb-4'>
                        <Row>
                            <Col xs={6}>
                                <table className='w-100'>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold', width: 170}}>Nombre</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textName" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Apellido</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textSurname" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>DNI</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textDni" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Rol</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Select className='cover content rows element' id="" onChange = {(event) => handleRole(event)}>
                                                    {roles.map((p, i) => (
                                                        <option key={p.name} id={i} value={p.id}>{p.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Correo electrónico</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textEmail" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>                                    
                                </table>
                            </Col>
                            <Col xs={6}>
                            <table className='w-100'>
                            <tr className={`${displayCustomer}`}>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Cliente</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" disabled id="textCustomer" placeholder="" />
                                            </Form.Group>
                                        </td>
                                        <td className='pt-2 ps-2' style={{width: 30}}>
                                            <Button variant="primary" onClick={handleOpenSearchCustomerModal}><FontAwesomeIcon icon={faSearch}/></Button>
                                        </td>
                                    </tr>
                                    <tr className={`${displayLawer}`}>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Abogado</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Select className='cover content rows element' id="" onChange = {(event) => handleIsLawer(event)}>
                                                    <option value={false}>No</option>
                                                    <option value={true}>Si</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr className={`${displayLawer}`}>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Folio</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textFolio" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr className={`${displayLawer}`}>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Tomo</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textTomo" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                </table>
                            </Col>
                            </Row>
                            <Form>

                                
                            </Form>

                            <div className='mt-5' style={{textAlign: 'right'}}>
                                <Button variant="success" onClick={createUser}>Siguiente<FontAwesomeIcon icon={faArrowRight} style={{marginLeft: 8}} /></Button>
                            </div>
                            
                        </Container>
                    </Card>
                </Row>
            </Container>

            <Modal show={showSearchCustomerModal} size='lg' onHide={handleCloseSearchCustomerModal}>
                <Modal.Header closeButton>
                <Modal.Title>Buscar cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="" autoFocus placeholder="Buscar..." onChange = {(event) => handleSearchCustomers(event)} />
                    {customers.length > 0 ? <p className='mt-4 mb-0'>Resultado de búsqueda:</p> : <span></span>}
                    <ListGroup className='mt-2'>
                        {customers.map((c, i) => (
                            <ListGroup.Item key={i} onClick={() => handleCustomerSelected(c.id, c.isBusiness)}>{c.isBusiness == true ? (<div><p className='m-0' style={{fontSize: 12}}>Persona jurídica</p><p className='m-0'></p>CUIT {c.cuit} - {c.name}</div>) : (<div><p className='m-0' style={{fontSize: 12}}>Persona física</p><p className='m-0'></p>DNI {c.dni} - {c.surname.toUpperCase()}, {c.name}</div>)}</ListGroup.Item>
                        ))}
                    </ListGroup>
            
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseSearchCustomerModal}>
                    Cerrar
                </Button>
                <Link to='/clientes/personas'>
                    <Button variant="primary">
                        Crear nuevo cliente
                    </Button>
                </Link>
                </Modal.Footer>
            </Modal>
        </div>
    )
}